import React from "react";
import { Modal } from "antd";
import "antd/dist/antd.css";

import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  WeiboShareButton,
  PocketShareButton,
  InstapaperShareButton,
  HatenaShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  WeiboIcon,
  HatenaIcon,
} from "react-share";

const text = "Check out teepmee.com !";

const Share = ({ show, setShow }) => {
  const handleCancel = () => {
    setShow(false);
  };
  return (
    <>
      <Modal
        visible={show}
        footer
        width="340px"
        onCancel={handleCancel}
        bodyStyle={{
          height: "250px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: 10,
            flexWrap: "wrap",
            marginTop: 30,
          }}
        >
          <div className="Demo__some-network">
            <FacebookShareButton
              url={text}
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>

          <div className="Demo__some-network">
            <FacebookMessengerShareButton
              url={text}
              appId="521270401588372"
              className="Demo__some-network__share-button"
            >
              <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
          </div>

          <div className="Demo__some-network">
            <TwitterShareButton
              url={text}
              className="Demo__some-network__share-button"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>

          <div className="Demo__some-network">
            <TelegramShareButton
              url={text}
              className="Demo__some-network__share-button"
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
          </div>

          <div className="Demo__some-network">
            <WhatsappShareButton
              url={text}
              separator=":: "
              className="Demo__some-network__share-button"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>

          <div className="Demo__some-network">
            <LinkedinShareButton
              url={text}
              className="Demo__some-network__share-button"
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </div>

          <div className="Demo__some-network">
            <PinterestShareButton
              url={String(window.location)}
              className="Demo__some-network__share-button"
            >
              <PinterestIcon size={32} round />
            </PinterestShareButton>
          </div>

          <div className="Demo__some-network">
            <VKShareButton
              url={text}
              className="Demo__some-network__share-button"
            >
              <VKIcon size={32} round />
            </VKShareButton>
          </div>

          <div className="Demo__some-network">
            <OKShareButton
              url={text}
              className="Demo__some-network__share-button"
            >
              <OKIcon size={32} round />
            </OKShareButton>
          </div>

          <div className="Demo__some-network">
            <RedditShareButton
              url={text}
              windowWidth={660}
              windowHeight={460}
              className="Demo__some-network__share-button"
            >
              <RedditIcon size={32} round />
            </RedditShareButton>
          </div>

          <div className="Demo__some-network">
            <TumblrShareButton
              url={text}
              className="Demo__some-network__share-button"
            >
              <TumblrIcon size={32} round />
            </TumblrShareButton>
          </div>

          <div className="Demo__some-network">
            <LivejournalShareButton
              url={text}
              description={text}
              className="Demo__some-network__share-button"
            >
              <LivejournalIcon size={32} round />
            </LivejournalShareButton>
          </div>

          <div className="Demo__some-network">
            <MailruShareButton
              url={text}
              className="Demo__some-network__share-button"
            >
              <MailruIcon size={32} round />
            </MailruShareButton>
          </div>

          <div className="Demo__some-network">
            <EmailShareButton
              url={text}
              body="body"
              className="Demo__some-network__share-button"
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
          <div className="Demo__some-network">
            <ViberShareButton
              url={text}
              className="Demo__some-network__share-button"
            >
              <ViberIcon size={32} round />
            </ViberShareButton>
          </div>

          <div className="Demo__some-network">
            <WorkplaceShareButton
              url={text}
              className="Demo__some-network__share-button"
            >
              <WorkplaceIcon size={32} round />
            </WorkplaceShareButton>
          </div>

          <div className="Demo__some-network">
            <LineShareButton
              url={text}
              className="Demo__some-network__share-button"
            >
              <LineIcon size={32} round />
            </LineShareButton>
          </div>

          <div className="Demo__some-network">
            <WeiboShareButton
              url={text}
              className="Demo__some-network__share-button"
            >
              <WeiboIcon size={32} round />
            </WeiboShareButton>
          </div>

          <div className="Demo__some-network">
            <PocketShareButton
              url={text}
              className="Demo__some-network__share-button"
            >
              <PocketIcon size={32} round />
            </PocketShareButton>
          </div>

          <div className="Demo__some-network">
            <InstapaperShareButton
              url={text}
              className="Demo__some-network__share-button"
            >
              <InstapaperIcon size={32} round />
            </InstapaperShareButton>
          </div>

          <div className="Demo__some-network">
            <HatenaShareButton
              url={text}
              windowWidth={660}
              windowHeight={460}
              className="Demo__some-network__share-button"
            >
              <HatenaIcon size={32} round />
            </HatenaShareButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Share;
