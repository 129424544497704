import React from "react";
import useApi from "../../../hooks/useApi";
import SignupScreen from "./SignupScreen";
import * as api from "../../../api/api";
import { useHistory } from "react-router";

const initialValues = {
  countryCode: "1",
  number: "",
};

const SignUp = () => {
  const history = useHistory();
  const { error, request } = useApi(api.signUser);
  async function handleSubmit(values) {
    try {
      const { data } = await request({
        phone_number: values.countryCode + "" + values.number,
      });
      localStorage.setItem("verification", data.data.verification_id);
      data && history.push("/verify");
    } catch (_) {}
  }
  return (
    <SignupScreen
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      error={error}
    />
  );
};

export default SignUp;
