// Render Prop
import React, {useEffect, useState} from "react";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import "./style.css";
import {Jumbotron, Row, Col} from "react-bootstrap";
import Sidebar from "../../Sidebar/Sidebar";
import {useHistory} from "react-router-dom";
import useApi, {PHONE_NO_ERROR} from "../../../hooks/useApi";
import * as api from "../../../api/api";
import nProgress from "nprogress";
import axios from "axios";
import loadScript from "../../helper-components/utils";
import AntdImgCrop from "antd-img-crop";
import {Upload} from "antd";

const initialValues = {
  name: "",
  phone: "",
  payment_type: "",
  payment_value: "",
  workplace_id: "",
  position: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Missing Field"),
  payment_type: Yup.string().required("Missing Field"),
  payment_value: Yup.string().required("Missing Field"),
  workplace_id: Yup.string().required("Missing Field"),
  position: Yup.string().required("Missing Field"),
});

const CreateUser = () => {
  const {error, request, data} = useApi(api.createUser);
  const history = useHistory();
  const [showSidebar, setShowSidebar] = useState(false);
  const [image, setImage] = useState("");
  const [previewImg, setPreviewImg] = useState();
  const [error1, setError] = useState("");
  const [place, setPlace] = useState({});

  useEffect(() => {
    if (!window.google) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places`
      );
      setTimeout(() => setPlace({}), 200);
    }
  }, []);

  useEffect(() => {
    if (error === PHONE_NO_ERROR) {
      handleCreateJob();
    }
  }, [error, data]);

  const handleCreateJob = async () => {
    const payload = {
      user_id: data?.id,
      workplace_name: place?.name,
      workplace_id: place?.place_id,
      workplace_address: place?.formatted_address,
      positions: [place?.values?.position],
      start_month: new Date().getMonth() + 1,
      start_year: new Date().getFullYear(),
    };
    try {
      nProgress.start();
      const res = await api.adminJobCreate(payload);
      if (res?.data) {
        history.push("/home");
      }
      nProgress.done();
    } catch (error) {
      nProgress.done();
    }
  };

  const fetchPlaceDetails = async (values) => {
    if (!window.google) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places`
      );
      setTimeout(() => setPlace({}), 200);
    }
    // if (!placeId) {
    //   setPlace(undefined);
    //   return;
    // }
    if (window.google) {
      const request = {placeId: values.workplace_id};
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.getDetails(request, async function (placeDetails, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          console.log({placeDetails});
          let payload = {
            workplace_name: placeDetails?.name,
            workplace_id: placeDetails?.place_id,
            workplace_address: placeDetails?.formatted_address,
          };
          setError("");
          setPlace({...placeDetails, values});
          try {
            const res = await api.createWorkplace(payload);
            await handleSubmit(values, payload);
          } catch (error) {
            console.log(error);
            console.log(error?.response);
          }
        } else if (status === "INVALID_REQUEST") {
          setError("Please enter a valid Business ID");
        }
      });
    }
  };

  const handleImage = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    const getBase64 = (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    };
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setPreviewImg(url);
        setImage(info.file.originFileObj);
      });
    }
  };

  const handlePaymentMethod = (values) => {
    switch (values.payment_type) {
      case "btc":
        return {key: "payment_btc", value: values.payment_value};
      case "eth":
        return {key: "payment_eth", value: values.payment_value};
      case "lydia":
        return {key: "payment_lydia", value: values.payment_value};
      case "venmo":
        return {key: "payment_venmo", value: values.payment_value};
      case "cashapp":
        return {key: "payment_cash_app", value: values.payment_value};
      case "chime":
        return {key: "payment_chime", value: values.payment_value};
      case "zelle":
        return {key: "payment_zelle", value: values.payment_value};
      case "paypal":
        return {key: "payment_pay_pal", value: values.payment_value};

      default:
        break;
    }
  };
  const handleSubmit = async (values, payload) => {
    if (values?.phone?.length == 0) {
      delete values["phone"];
    }
    const payment = handlePaymentMethod(values);
    values[payment.key] = payment.value;
    delete values?.payment_type;
    delete values?.payment_value;
    // if (values?.phone && values.phone[0] != "+") {

    //   setPhoneNumberError(
    //     "Format must be: +xxxxx. For USA: +1 followed by 10 digits. Example: +12015253463"
    //   );
    //   return;
    // } else {
    //   setPhoneNumberError("");
    // }
    const formData = new FormData();
    formData.append("avatar", image);
    try {
      const {data} = await request(values, payload);
      nProgress.start();
      if (image) {
        try {
          const response = await axios.post(
            `https://api.teepmee.com/admin/users/avatar/${data.data.user.id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("token"),
              },
            }
          );
          history.push("/home");
        } catch (error) {
          nProgress.done();
          console.log(error.response);
        }
      }
      setError("");
      !image && history.push("/home");
    } catch (error) {
      console.log(error.response);
      // setError("Something went wrong.");
    }
    nProgress.done();
  };
  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  return (
    <div>
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />

      <Jumbotron className="mb-0 pb-5">
        <Row className="justify-content-center">
          <Col className="col-lg-6 col-md-10 col-12 col-sm-12 d-flex justify-content-between">
            <div>
              <i
                class="fas fa-bars icons"
                onClick={() => setShowSidebar(true)}
              ></i>
            </div>
          </Col>
        </Row>
      </Jumbotron>
      <section class="login_section">
        <div class="login_container">
          <div class="">
            <div class="login_form payment_form">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={fetchPlaceDetails}
              >
                <Form className="user-form">
                  <div>
                    <label>Name</label>
                    <br />
                    <Field name="name" />
                  </div>
                  <ErrorMessage
                    name="name"
                    component="div"
                    style={{color: "red"}}
                  />
                  <div>
                    <label>Phone Number (Optional)</label>
                    <br />
                    <Field
                      type="number"
                      name="phone"
                      style={{border: "1px solid"}}
                    />
                  </div>
                  <ErrorMessage
                    name="phone"
                    component="div"
                    style={{color: "red"}}
                  />

                  <div>
                    <label>Payment</label>
                    <br />
                    <div className="payment-select">
                      <Field as="select" name="payment_type">
                        <option value="" selected disabled>
                          -select-
                        </option>

                        <option value="btc">BTC</option>
                        <option value="eth">ETH</option>
                        <option value="lydia">Lydia</option>
                        <option value="venmo">Venmo</option>
                        <option value="cashapp">Cashapp</option>
                        <option value="chime">Chime</option>
                        <option value="zelle">Zelle</option>
                        <option value="paypal">Paypal</option>
                      </Field>
                      <Field name="payment_value" />
                    </div>
                  </div>
                  <div style={{display: "flex", gap: "10px"}}>
                    <ErrorMessage
                      name="payment_type"
                      component="div"
                      style={{color: "red"}}
                    />
                    <ErrorMessage
                      name="payment_value"
                      component="div"
                      style={{color: "red"}}
                    />
                  </div>

                  <div>
                    <label>Business ID</label>
                    <br />
                    <Field name="workplace_id" />
                  </div>
                  <ErrorMessage
                    name="workplace_id"
                    component="div"
                    style={{color: "red"}}
                  />
                  <div>
                    <label>Job</label>
                    <br />
                    <Field name="position" />
                  </div>
                  <ErrorMessage
                    name="position"
                    component="div"
                    style={{color: "red"}}
                  />
                  {error1 && <p style={{color: "red"}}>{error1}</p>}
                  <div class="change_pic create-user-image">
                    <span style={{display: "inline-block", marginTop: 10}}>
                      Photo (Optional)
                    </span>
                    <label class="camera_lael">
                      <figure>
                        {previewImg ? (
                          <img
                            src={previewImg}
                            alt=""
                            style={{width: 40, height: 40, borderRadius: "50%"}}
                          />
                        ) : null}
                        {!previewImg && (
                          <div class="change_pic">
                            <AntdImgCrop
                              showGrid
                              rotationSlider
                              aspectSlider
                              showReset
                            >
                              <Upload
                                customRequest={dummyRequest}
                                onChange={handleImage}
                                showUploadList={false}
                                multiple={false}
                                maxCount={1}
                              >
                                <label class="camera_label">
                                  <i class="fas fa-camera"></i>
                                </label>
                              </Upload>
                            </AntdImgCrop>
                          </div>
                        )}
                      </figure>
                    </label>
                  </div>
                  <Row className="justify-content-center pt-3">
                    <Col className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <button
                        type="submit"
                        className="btn btn-warning btn-lg w-100"
                      >
                        Create
                      </button>
                    </Col>
                  </Row>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateUser;
