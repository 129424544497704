import React, { useState } from "react";
import { Modal, Button } from "antd";
import "antd/dist/antd.css";
import zele from "../assets/images/zele.png";
import "./Modal.css";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ChimeModal = ({ profile, show, setShow }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
    setShow(false);
  };

  const handleCancel = () => {
    setShow(false);
    setIsModalVisible(false);
    setCopied(false);
  };

  return (
    <div>
      <Modal
        visible={show}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[]}
        width="340px"
        bodyStyle={{ height: "250px", borderRadius: "15px" }}
        closable={false}
      >
        <p
          className=""
          style={{ margin: 10, marginTop: 30, fontSize: "1.3rem" }}
        >
          Thank you. Please send your Chime to
        </p>
        <div className="copy-area">
          <span class="btn-icon chime-text">
            <i className="fab fa-cuttlefish"></i>
          </span>
          <span className="copy-profile">{profile}</span>
          <CopyToClipboard text={profile} onCopy={() => setCopied(true)}>
            <i class="fas fa-clone copy-icon"></i>
          </CopyToClipboard>
        </div>
        {copied && (
          <p style={{ marginTop: "0px", color: "red", textAlign: "center" }}>
            Copied! Just paste it in Chime!
          </p>
        )}
        <div style={{ textAlign: "right", width: "95%" }}>
          <button
            className="close-btn"
            style={{ marginTop: "40px" }}
            onClick={handleCancel}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ChimeModal;
