import React, { useEffect, useState } from "react";
import EditPaymentScreen from "./EditPaymentScreen";
import useApi from "../../../hooks/useApi";
import * as api from "../../../api/api";
import { useHistory } from "react-router-dom";

const EditPayment = () => {
  const history = useHistory()
  const userProfile = useApi(api.getProfile);
  const userUpdate = useApi(api.adminUpdateUser);
  const deletePay = useApi(api.deletePayment);
  const { request } = useApi(api.addPayment);
  const [btc, setBtc] = useState("");
  const [eth, setEth] = useState("");
  const [lydia, setLydia] = useState("");
  const [zelle, setZelle] = useState("");
  const [vimeo, setVimeo] = useState("");
  const [paypal, setPaypal] = useState("");
  const [chime, setChime] = useState("");
  const [cashapp, setCashapp] = useState("");

  const userId = window.location.href.split("edit-payment/")[1];

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await userProfile.request(userId);
      } catch (_) {}
    }
    fetchData();
  }, []);

  // console.log("userdata", userProfile.data);

  async function handleSubmit(value, type) {
    try {
      await userUpdate.request({ ...userProfile?.data?.data, [type]:value });
      history.replace(`/u/${userProfile.data?.data?.user_name}`)
    } catch (_) {}
  }
  async function deletePayment(type) {
    try {
      await userUpdate.request({ ...userProfile?.data?.data, [type]:'' });
      history.replace(`/u/${userProfile.data?.data?.user_name}`)
    } catch (_) {}
  }
  // console.log("data", data);
  return (
    <EditPaymentScreen
      handleSubmit={handleSubmit}
      btc={btc}
      setBtc={setBtc}
      eth={eth}
      setEth={setEth}
      lydia={lydia}
      setLydia={setLydia}
      zelle={zelle}
      setZelle={setZelle}
      vimeo={vimeo}
      setVimeo={setVimeo}
      paypal={paypal}
      setPaypal={setPaypal}
      chime={chime}
      setChime={setChime}
      setCashapp={setCashapp}
      cashapp={cashapp}
      userData={userProfile.data}
      deletePayment={deletePayment}
    />
  );
};

export default EditPayment;
