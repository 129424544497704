import React from "react";
import "./UploadAvatar.css";
import img from "../../assets/images/img_avatar.png";
import ImgCrop from "antd-img-crop";
import {Upload} from "antd";

const UploadAvatarScreen = ({
  handleImage,
  handleSubmit,
  response,
  image,
  handleSkip,
}) => {
  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  return (
    <div className="main-body">
      <section class="login_section">
        <div class="login_container">
          <div class="login_box">
            <div class="login_form">
              <div class="skip_btn">
                <a onClick={handleSkip}>Skip</a>
              </div>
              <h5>Welcome To</h5>
              <div class="company_logo">
                <a href="#">
                  <span>teep</span>
                  <i class="fas fa-circle"></i>
                  <span>me</span>
                </a>
              </div>
              <form onSubmit={handleSubmit}>
                <div class="login_fields">
                  <div class="input_field">
                    <label class="upload_photo">Upload a photo</label>
                    <div class="input_figure_field">
                      <figure>
                        {image ? (
                          <img src={image} alt="" />
                        ) : (
                          <img src={img} alt="" />
                        )}
                        <div class="change_pic">
                          <ImgCrop
                            showGrid
                            rotationSlider
                            aspectSlider
                            showReset
                          >
                            <Upload
                              customRequest={dummyRequest}
                              onChange={handleImage}
                              showUploadList={false}
                              multiple={false}
                              maxCount={1}
                            >
                              <label class="camera_label">
                                <i class="fas fa-camera"></i>
                              </label>
                            </Upload>
                          </ImgCrop>
                        </div>
                      </figure>
                    </div>
                  </div>

                  <div class="terms_and_conditions">
                    <p>
                      Put Tip:A Recent photo helps customers to find you easily!
                    </p>
                  </div>
                  {response && <p style={{color: "green"}}>Image Uploaded</p>}
                  <div class="submit_btn">
                    <button type="submit">Next</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UploadAvatarScreen;
