import React, { useState } from "react";
import { Modal } from "antd";
// import "./Modal.css";

const DeleteModal = ({ show, setShow, handleDelete }) => {
  const handleCancel = () => {
    setShow(false);
  };

  return (
    <div>
      <Modal
        visible={show}
        centered
        footer={null}
        width="240px"
        bodyStyle={{
          height: "150px",
          padding: 15,
        }}
        closable={false}
      >
        <p>Are you sure you want to delete this user?</p>
        <div className="modal-buttons">
          <button className="btn btn-warning" onClick={handleDelete}>
            Yes
          </button>
          <button className="btn btn-warning" onClick={handleCancel}>
            No
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteModal;
