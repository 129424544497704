import React, { useEffect } from "react";
import AddJobScreen from "./AddJobScreen";
import * as api from "../../api/api";
import useApi from "../../hooks/useApi";
import { useHistory } from "react-router";

const initialValues = {
  positions: [],
  startDate: "",
};

const AddJob = ({ place }) => {
  const history = useHistory();
  const { data, request } = useApi(api.getJobPositions);
  const userProfile = useApi(api.getUserProfile);
  const createJob = useApi(api.createJob);
  const fetchJobs = async () => {
    try {
      await request();
    } catch (_) {}
  };
  useEffect(() => {
    fetchJobs();
  }, []);
  useEffect(() => {
    async function fetchUser() {
      try {
        await userProfile.request();
      } catch (_) {}
    }
    fetchUser();
  }, []);

  const handleSubmit = async (values) => {
    let positions = [];
    if (values.other) {
      positions = [...values.positions, values.other];
    } else {
      positions = values.positions;
    }
    let responseObj = {
      positions,
      workplace_id: place ? place.place_id : "independent",
      workplace_address: place ? place.formatted_address : "Independent",
      workplace_name: place ? place.name : "Independent",
      start_month: new Date().getMonth() + 1,
      start_year: new Date().getFullYear(),
    };
    try {
      const data = await createJob.request(responseObj);
      data &&
        userProfile.data &&
        (userProfile.data.data.on_boarding_completed
          ? history.push("/paymentMethods")
          : history.push("/congratulations"));
    } catch (_) {}
  };

  return (
    <>
      {data && (
        <AddJobScreen
          data={data.data}
          initialValues={initialValues}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default AddJob;
