import React, {useState, useEffect} from "react";

import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {Row, Container, Jumbotron, Col} from "react-bootstrap";

// import { EmployeeFilter, EmployeesList } from "./index";
import loadScript from "./helper-components/utils";
import AddJob from "./AddJob/AddJob";
// import { useLocation } from "react-router";
const BusinessProfile = (props) => {
  const [place, setPlace] = useState({});
  const [placePicture, setPlacePicture] = useState("");

  const urlPath = window.location.href;
  // console.log("url", urlPath);
  const placeId = urlPath.split("b/")[1] || props.placeID;
  // console.log("placeid", placeId);
  useEffect(() => {
    if (!window.google) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places`
      );
      setTimeout(() => setPlace({}), 200);
    }
    if (placeId === "independent") {
      setPlace(undefined);
      return;
    }
    if (window.google) {
      const request = {placeId};
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.getDetails(request, function (placeDetails, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setPlace(placeDetails);
          placeDetails.photos &&
            setPlacePicture(placeDetails.photos[0].getUrl({maxWidth: 512}));
        }
      });
    }
  }, [placeId, place]);

  // console.log("place", place);

  // if (!place) {
  //   return <h1 className="text-center">Page not found</h1>;
  // }

  return (
    // Object.keys(place).length > 0 && (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Teepmee - ${!place ? "Independent" : place.name}`}</title>
      </Helmet>
      <div>
        <Jumbotron
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${placePicture})`,
          }}
          className="hero-image"
        >
          <Row className="justify-content-center">
            <Col className="col-lg-6 col-md-10 col-12 col-sm-12">
              <Link to="/workplace" className="btn bg-white">
                <i className="fa fa-arrow-left" />
              </Link>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5 mb-0">
            <Col className="col-lg-6 col-md-10 col-12 col-sm-12">
              <h4 style={{color: "white"}}>
                {!place ? "Independent" : place.name}
              </h4>
              <p className="small">
                {!place ? "Independent" : place.formatted_address}
              </p>
            </Col>
          </Row>
        </Jumbotron>
        <Row className="justify-content-center m-auto">
          <Col className="col-lg-6 col-md-10 col-12 col-sm-12">
            {/* <EmployeeFilter /> */}
            {/* <EmployeesList placeId={place.place_id} /> */}
            <AddJob place={place} />
          </Col>
        </Row>
      </div>
    </>
    // )
  );
};

BusinessProfile.propTypes = {
  placeID: PropTypes.string,
};

BusinessProfile.defaultProps = {
  placeID: null,
};

const mapStateToProps = (state) => {
  return {
    placeID: state.appReducer.placeID,
  };
};

export default connect(mapStateToProps, null)(BusinessProfile);
