import React, {useState} from "react";
import UploadAvatarScreen from "./UploadAvatarScreen";
import axios from "axios";
import nProgress from "nprogress";
import {useHistory} from "react-router";
import {api} from "../../api/api";
import {useSelector} from "react-redux";

const UploadAvatar = () => {
  const link = useSelector((state) => state.linkReducer);
  const history = useHistory();
  const [image, setImage] = useState("");
  const [response, setResponse] = useState("");
  const [previewImg, setPreviewImg] = useState();

  const handleImage = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    const getBase64 = (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    };
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setPreviewImg(url);
        setImage(info.file.originFileObj);
      });
    }
  };

  const handleSkip = () => {
    link.businessId
      ? history.push("/workplace?businessId=" + link.businessId)
      : history.push("/workplace");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("avatar", image);
    nProgress.start();
    try {
      const {data} = await axios.post(
        `https://api.teepmee.com/users`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setResponse(data);
      // console.log("uploaded image", data);
      data &&
        (link.businessId
          ? history.push("/workplace?businessId=" + link.businessId)
          : history.push("/workplace"));
    } catch (error) {
      nProgress.done();
      console.log(error);
    }
    nProgress.done();
  };

  return (
    <UploadAvatarScreen
      handleImage={handleImage}
      handleSubmit={handleSubmit}
      response={response}
      image={previewImg}
      handleSkip={handleSkip}
    />
  );
};

export default UploadAvatar;
