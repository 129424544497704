import React, {useEffect, useState} from "react";
import "./Sidebar.css";
import img from "../../assets/images/img_avatar.png";
import * as api from "../../api/api";
import useApi from "../../hooks/useApi";
import {Link} from "react-router-dom";
import Share from "../Share/Share";
import admin from "../../assets/images/admin.png";

const Sidebar = ({showSidebar, setShowSidebar}) => {
  const [share, setShare] = useState(false);
  const {request, data} = useApi(api.getUserProfile);
  useEffect(() => {
    async function fetchData() {
      try {
        await request();
      } catch (_) {}
    }
    fetchData();
  }, []);
  function handleLogout() {
    window.location.href = "/home";
    localStorage.removeItem("verification");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("isMasterAdmin");
    window.location.reload();
  }
  return (
    <section class={`side_bar ${showSidebar ? "show-sidebar" : ""}`}>
      <a class="closebtn sidebar-btn">
        <i class="fas fa-arrow-left" onClick={() => setShowSidebar(false)}></i>
      </a>
      <>
        {data && (
          <div class="side_bar_logo">
            <figure>
              <img src={data.data.photo_url} alt="" />
            </figure>
            <h3>{data.data.name}</h3>
            <span>{data.data.user_name}</span>
          </div>
        )}

        {!localStorage.getItem("token") && (
          <div class="side_bar_logo">
            <figure>
              <img
                src="https://nyc3.digitaloceanspaces.com/teepmee/users/default_avatar.jpg"
                alt=""
              />
            </figure>
            <h3>Account</h3>
            <a href="/" style={{color: "white"}}>
              Login Now
            </a>
          </div>
        )}

        <div class="dashboard_buttons" id="change_active">
          {localStorage.getItem("isMasterAdmin") === "2" && (
            <Link to="/create-user">
              {" "}
              <img src={admin} alt="admin_icon" className="admin-icon" />{" "}
              <a> User Creation</a>
            </Link>
          )}
          <Link onClick={() => setShare(!share)}>
            {" "}
            <i class="fas fa-share"></i> <a> Share</a>
          </Link>

          <Link
            to="#"
            onClick={(e) => {
              window.location = "mailto:support@teepmee.com";
              e.preventDefault();
            }}
          >
            <i class="fas fa-star"></i> Contact Support
          </Link>
          <a
            href="https://getterms.io/view/XkS4A/privacy/en-us"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <i class="far fa-user-secret"></i> Privacy
          </a>
          {localStorage.getItem("token") && (
            <a href="/home" onClick={handleLogout}>
              <i class="far fa-sign-out-alt"></i> Logout
            </a>
          )}
        </div>
      </>
      <Share show={share} setShow={setShare} />
    </section>
  );
};

export default Sidebar;
