import React from "react";
import useApi from "../../../hooks/useApi";
import VerficationScreen from "./VerficationScreen";
import * as api from "../../../api/api";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";

const initialValues = {
  code: "",
};

const Verification = () => {
  const history = useHistory();
  const link = useSelector((state) => state.linkReducer);
  // console.log("redux business Id", businessId);
  const {request, error} = useApi(api.verifyUser);
  async function handleSubmit(values) {
    try {
      const {data} = await request({
        verification_id: localStorage.getItem("verification"),
        auth_code: values.code,
      });
      localStorage.setItem("isMasterAdmin", data.data.user.role);
      data && localStorage.setItem("token", data.data.token);
      data && localStorage.setItem("userId", data.data.user.id);
      data &&
        data.data.user.on_boarding_completed &&
        (link.businessId
          ? history.push("/workplace?businessId=" + link.businessId)
          : history.push("/main_profile"));
      data &&
        !data.data.user.on_boarding_completed &&
        history.push("/profileName");
      // data && window.location.reload();
    } catch (_) {}
  }
  return (
    <VerficationScreen
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      error={error}
    />
  );
};

export default Verification;
