import { Field, Form, Formik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import "./Signup.css";

const SignupScreen = ({ initialValues, handleSubmit, error }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <div className="main-body">
        <section class="login_section">
          <div class="login_container">
            <div class="login_box">
              <div class="login_form">
                <h5>Welcome To</h5>
                <div class="company_logo">
                  <Link to="home">
                    <span>teep</span>
                    <i class="fas fa-circle"></i>
                    <span>mee</span>
                  </Link>
                </div>
                <Form>
                  <div class="login_fields">
                    <div class="input_field">
                      <label>Enter Mobile Number</label>
                      <div class="input_phone_field">
                        <div className="code-container">
                          <i className="fas fa-plus plus-icon"></i>
                          <Field
                            className="code"
                            name="countryCode"
                            type="number"
                            placeholder="1"
                            required
                          />
                        </div>
                        <div className="number-container">
                          <Field
                            name="number"
                            type="number"
                            placeholder="789786786"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div class="terms_and_conditions">
                      <p>By using this app,you agree to our</p>
                      <a
                        href="https://getterms.io/view/XkS4A/tos/en-us"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Terms and conditions
                      </a>
                      <a
                        href="https://getterms.io/view/XkS4A/privacy/en-us"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Privacy policy
                      </a>
                    </div>
                    {error.data && (
                      <p style={{ color: "red" }}>{error.data.message}</p>
                    )}
                    <div className="submit_btn">
                      <button type="submit">Send Code</button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Formik>
  );
};

export default SignupScreen;
