import * as TYPES from "./actionTypes";

export const setSearchResults = (results, location, businessName) => ({
  type: TYPES.SET_SEARCH_RESULTS,
  payload: { results, location, businessName },
});

export const setWorkplaceSearchResults = (results, location, businessName) => ({
  type: TYPES.SET_WORKPLACE_SEARCH_RESULTS,
  payload: { results, location, businessName },
});

export const selectBusiness = (placeID) => ({
  type: TYPES.SELECT_BUSINESS,
  payload: placeID,
});
