import React, {useEffect, useState} from "react";

import {Helmet} from "react-helmet";
import {connect} from "react-redux";

import {
  Alert,
  Container,
  Jumbotron,
  Button,
  InputGroup,
  FormControl,
  Col,
  Row,
} from "react-bootstrap";

import location_icon from "../../assets/images/location.png";
import building_icon from "../../assets/images/building.png";
import {MobileBanner, Listing} from "../../components";

import {setWorkplaceSearchResults} from "../../redux/actions";
import PropTypes from "prop-types";

import loadScript from "../../components/helper-components/utils";
import Sidebar from "../Sidebar/Sidebar";
import WorkplaceBanner from "../WorkplaceBanner/WorkplaceBanner";
import {Link, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";

// import BottomNav from "./BottomNav/BottomNav";
const WorkplaceHome = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [placeId, setPlaceId] = useState(
    props.history.location.search.split("=")[1]
  );
  const [place, setPlace] = useState();
  const [placePicture, setPlacePicture] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [query, setQuery] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  // const { data, request } = useApi(api.getUserProfile);
  const [showError, setShowError] = useState(false);
  const [location, setLocation] = useState(props.location);
  const [businessName, setBusinessName] = useState(props.businessName);
  // console.log("profile", data.data);
  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       await request();
  //     } catch (_) {}
  //   }
  //   fetchData();
  // }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      dispatch({type: "link/saveId", payload: {businessId: placeId}});
      history.push("/login");
    }
    if (!window.google) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places`
      );
      setTimeout(() => setPlace({}), 200);
    }
    if (!placeId) {
      setPlace(undefined);
      return;
    }
    if (window.google) {
      const request = {placeId};
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.getDetails(request, function (placeDetails, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setPlace(placeDetails);
          placeDetails.photos &&
            setPlacePicture(placeDetails.photos[0].getUrl({maxWidth: 512}));
        }
      });
    }
  }, [placeId, place]);

  useEffect(() => {
    if (!window.google) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places`
      );
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (query) {
      const request = {query};
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.textSearch(request, function (apiResults, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          props.setWorkplaceSearchResults(apiResults, location, businessName);
        }
      });
    }
  }, [query]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (businessName === "" && location === "") {
      setShowError(true);
    } else {
      setShowError(false);
      setQuery(businessName + " " + location);
    }
  };

  function handleClick() {
    history.replace("/workplace");
    window.location.reload();
  }

  return (
    <div className="abc">
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Teepmee - ${!place ? "Independent" : place.name}`}</title>
      </Helmet>
      <div>
        <div>
          {placeId ? (
            <>
              <Jumbotron
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${placePicture})`,
                }}
                className="hero-image"
              >
                <Row className="justify-content-center">
                  <Col className="col-lg-6 col-md-10 col-12 col-sm-12">
                    <p onClick={handleClick} className="btn bg-white">
                      <i className="fa fa-arrow-left" />
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-center mt-5 mb-0">
                  <Col className="col-lg-6 col-md-10 col-12 col-sm-12">
                    <h4 style={{color: "white"}}>
                      {!place ? "Independent" : place.name}
                    </h4>
                    <p className="small">
                      {!place ? "Independent" : place.formatted_address}
                    </p>
                  </Col>
                </Row>
              </Jumbotron>
              <div>
                <Col
                  className="col-lg-6 col-md-10 col-12 col-sm-12"
                  style={{
                    display: "flex",
                    margin: "auto",
                    justifyContent: "center",
                  }}
                >
                  <div className="deeplink-work">
                    <h4>Do you work here?</h4>
                    <div>
                      <button className="btn btn-warning ">
                        <Link to={`/main_b/${placeId}`}>Yes</Link>
                      </button>
                      <button className="btn btn-warning" onClick={handleClick}>
                        No
                      </button>
                    </div>
                  </div>
                </Col>
              </div>
              {/* <div></div> */}
            </>
          ) : (
            <Jumbotron className="mb-0">
              <Row className="justify-content-center">
                <Col className="col-lg-8 col-md-10">
                  <div>
                    <i
                      class="fas fa-bars icons"
                      onClick={() => setShowSidebar(true)}
                    ></i>
                  </div>
                  <h1 style={{color: "white"}}>Let’s add your workplace!</h1>
                  <p>
                    Please indicate where you work. This way, customers will be
                    able to find you easily!
                  </p>
                  <Row>
                    {showError && (
                      <Col className="col-12">
                        <Alert variant="danger" className="text-danger">
                          <i className="fa fa-exclamation-triangle pr-2" />{" "}
                          Please enter at least location or business name!
                        </Alert>
                      </Col>
                    )}
                    <Col className="col-md-6 col-12">
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="location-icon">
                            <img alt="" src={location_icon} />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Enter City"
                          aria-label="location"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                        />
                      </InputGroup>
                    </Col>
                    <Col className="col-md-6 col-12">
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="building-icon">
                            <img alt="" src={building_icon} />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Enter business name"
                          aria-label="business name"
                          value={businessName}
                          onChange={(e) => setBusinessName(e.target.value)}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <p>
                    <Button
                      onClick={handleSubmit}
                      type="submit"
                      className="float-right"
                      variant="warning"
                    >
                      Search
                    </Button>
                  </p>
                </Col>
              </Row>
            </Jumbotron>
          )}
          {!placeId && <WorkplaceBanner />}
          <Listing type={1} />
        </div>
      </div>
      {/* <BottomNav index={0} /> */}
    </div>
  );
};

WorkplaceHome.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  setWorkplaceSearchResults: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    results: state.workplaceReducer.searchResult,
    location: state.workplaceReducer.location,
    businessName: state.workplaceReducer.businessName,
  };
};

export default connect(mapStateToProps, {setWorkplaceSearchResults})(
  WorkplaceHome
);
