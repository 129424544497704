import axios from "axios";

export const api = axios.create({
  baseURL: "https://api.teepmee.com",
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["authorization"] = token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export function signUser(data) {
  return api.post(`/authentication/request`, {...data});
}
export function verifyUser(data) {
  return api.post(`/authentication/verify`, {...data});
}
export function profileName(data) {
  return api.put(`/users`, {...data});
}
export function getJobPositions(data) {
  return api.get(`/positions`, {...data});
}
export function createJob(data) {
  return api.post(`/jobs`, {...data});
}
export function addPayment(data) {
  return api.put(`/payment`, {...data});
}
export function deletePayment(data) {
  return api.delete(`/payment/${data}`);
}
export function updateUser(data) {
  return api.put(`/users`, {...data});
}
export function getUserProfile() {
  return api.get(`/users/${localStorage.getItem("userId")}`);
}
export function getProfile(data) {
  return api.get(`/users/${data}`);
}
export function getWorkplaces() {
  return api.get(`/jobs`);
}

export function createWorkplace(data) {
  return api.post(`/admin/workplaces`, data);
}

export function getUserBusinesses() {
  return api.get(`/admin/workplaces`);
}

export function createUser(data) {
  console.log({data});
  return api.post(`/admin/users`, data);
}

export function adminJobCreate(data) {
  return api.post(`/admin/jobs`, data);
}
export function jobDelete(data) {
  return api.delete(`/admin/jobs/${data}`);
}

export function adminGetUsers(placeId) {
  return api.get(`/admin/workplaces/${placeId}`);
}

export function adminUpdateUser(data) {
  return api.put(`/admin/users/${data?.id}`, {...data});
}
