import React from "react";
import { useHistory } from "react-router";
import ProfileNameScreen from "./ProfileNameScreen";
import useApi from "../../hooks/useApi";
import * as api from "../../api/api";

const initialValues = {
  name: "",
};

const ProfileName = () => {
  const { data, request } = useApi(api.updateUser);
  const history = useHistory();
  async function handleSubmit(values) {
    try {
      const { data } = await request(values);
      data && history.push("/avatar");
    } catch (_) {}
  }
  return (
    <ProfileNameScreen
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    />
  );
};

export default ProfileName;
