import React, { useState } from "react";
// import "./PaymentMethods.css";
import { Jumbotron, Row, Col } from "react-bootstrap";

import btcImg from "../../../assets/images/btc.png";
import ethImg from "../../../assets/images/eth.png";
import lydiaImg from "../../../assets/images/lydia.png";
import lydiaImg2 from "../../../assets/images/lydia2.png";
import zelleImg from "../../../assets/images/zelle.png";
import chimeImg from "../../../assets/images/chime.png";
import cashAppImg from "../../../assets/images/cashapp.png";
import paypaLImg from "../../../assets/images/paypal.png";
import viemoImg from "../../../assets/images/viemo.png";

import Sidebar from "../../Sidebar/Sidebar";
// import BottomNav from "../BottomNav/BottomNav";

const PaymentMethodsScreen = ({
  handleSubmit,
  btc,
  setBtc,
  eth,
  setEth,
  lydia,
  setLydia,
  chime,
  setChime,
  zelle,
  setZelle,
  vimeo,
  setVimeo,
  paypal,
  setPaypal,
  cashapp,
  setCashapp,
  userData,
  deletePayment,
}) => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div className="abc">
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />

      <Jumbotron className="mb-0 pb-5">
        <Row className="justify-content-center">
          <Col className="col-lg-6 col-md-10 col-12 col-sm-12 d-flex justify-content-between">
            <div>
              <i
                class="fas fa-bars icons"
                onClick={() => setShowSidebar(true)}
              ></i>
            </div>
          </Col>
        </Row>
      </Jumbotron>
      <section class="login_section">
        <div class="login_container">
          <div class="">
            <div class="login_form payment_form">
              {localStorage.getItem("isMasterAdmin") === "2" && userData && (
                <p style={{ marginTop: 10, marginLeft: 20 }}>
                  Modifying for:{" "}
                  <span style={{ color: "orange" }}>
                    {userData.data.user_name}
                  </span>
                </p>
              )}
              <div class="added_payment_list">
                {userData && userData.data.payment_btc && (
                  <div class="single_payment_list">
                    <div class="single_payment_list_data">
                      <figure>
                        <img src={btcImg} alt="" style={{ width: "45px" }} />
                      </figure>
                      <input
                        type="text"
                        value={userData.data.payment_btc}
                        disabled
                      />
                    </div>
                    <a>
                      <i
                        class="fas fa-trash-alt"
                        onClick={() => deletePayment("payment_btc")}
                      ></i>
                    </a>
                  </div>
                )}
                {userData && userData.data.payment_eth && (
                  <div class="single_payment_list">
                    <div class="single_payment_list_data">
                      <figure>
                        <img
                          src={ethImg}
                          alt=""
                          style={{
                            width: "45px",
                            background: "#c4c1c1",
                            borderRadius: "50%",
                          }}
                        />
                      </figure>
                      <input
                        type="text"
                        value={userData.data.payment_eth}
                        disabled
                      />
                    </div>
                    <a>
                      <i
                        class="fas fa-trash-alt"
                        onClick={() => deletePayment("payment_eth")}
                      ></i>
                    </a>
                  </div>
                )}
                {userData && userData.data.payment_lydia && (
                  <div class="single_payment_list">
                    <div class="single_payment_list_data">
                      <figure>
                        <img src={lydiaImg2} alt="" style={{ width: "45px" }} />
                      </figure>
                      <input
                        type="text"
                        value={userData.data.payment_lydia}
                        disabled
                      />
                    </div>
                    <a>
                      <i
                        class="fas fa-trash-alt"
                        onClick={() => deletePayment("payment_lydia")}
                      ></i>
                    </a>
                  </div>
                )}
                {userData && userData.data.payment_zelle && (
                  <div class="single_payment_list">
                    <div class="single_payment_list_data">
                      <figure>
                        <img
                          src={zelleImg}
                          alt=""
                          style={{
                            borderRadius: "50%",
                            // height: "35px",
                            width: "45px",
                          }}
                        />
                      </figure>
                      <input
                        type="text"
                        value={userData.data.payment_zelle}
                        disabled
                      />
                    </div>
                    <a>
                      <i
                        class="fas fa-trash-alt"
                        onClick={() => deletePayment("payment_zelle")}
                      ></i>
                    </a>
                  </div>
                )}
                {userData && userData.data.payment_pay_pal && (
                  <div class="single_payment_list">
                    <div class="single_payment_list_data">
                      <figure>
                        <img
                          src={paypaLImg}
                          alt=""
                          style={{
                            background: "#c4c1c1",
                            borderRadius: "50%",
                            width: "45px",
                          }}
                        />
                      </figure>
                      <input
                        type="text"
                        value={userData.data.payment_pay_pal}
                        disabled
                      />
                    </div>
                    <a>
                      <i
                        class="fas fa-trash-alt"
                        onClick={() => deletePayment("payment_pay_pal")}
                      ></i>
                    </a>
                  </div>
                )}
                {userData && userData.data.payment_chime && (
                  <div class="single_payment_list">
                    <div class="single_payment_list_data">
                      <figure>
                        <img
                          src={chimeImg}
                          alt=""
                          style={{
                            borderRadius: "50%",
                            width: "45px",
                          }}
                        />
                      </figure>
                      <input
                        type="text"
                        value={userData.data.payment_chime}
                        disabled
                      />
                    </div>
                    <a>
                      <i
                        class="fas fa-trash-alt"
                        onClick={() => deletePayment("payment_chime")}
                      ></i>
                    </a>
                  </div>
                )}
                {userData && userData.data.payment_venmo && (
                  <div class="single_payment_list">
                    <div class="single_payment_list_data">
                      <figure>
                        <img
                          src={viemoImg}
                          alt=""
                          style={{
                            borderRadius: "50%",
                            width: "48px",
                            height: "48px",
                          }}
                        />
                      </figure>
                      <input
                        type="text"
                        value={userData.data.payment_venmo}
                        disabled
                      />
                    </div>
                    <a>
                      <i
                        class="fas fa-trash-alt"
                        onClick={() => deletePayment("payment_venmo")}
                      ></i>
                    </a>
                  </div>
                )}
                {userData && userData.data.payment_cash_app && (
                  <div class="single_payment_list">
                    <div class="single_payment_list_data">
                      <figure>
                        <img
                          src={cashAppImg}
                          alt=""
                          style={{
                            borderRadius: "50%",
                            width: "48px",
                          }}
                        />
                      </figure>
                      <input
                        type="text"
                        value={userData.data.payment_cash_app}
                        disabled
                      />
                    </div>
                    <a>
                      <i
                        class="fas fa-trash-alt"
                        onClick={() => deletePayment("payment_cash_app")}
                      ></i>
                    </a>
                  </div>
                )}
              </div>

              <div class="login_fields">
                <div id="accordion">
                  <div class="input_field">
                    {userData &&
                      (!userData.data.payment_btc ||
                        !userData.data.payment_eth ||
                        !userData.data.payment_lydia ||
                        !userData.data.payment_zelle ||
                        !userData.data.payment_venmo ||
                        !userData.data.payment_pay_pal ||
                        !userData.data.payment_cash_app ||
                        !userData.data.payment_chime) && (
                        <label>Add a Payment Method</label>
                      )}
                    <div class="payment_methods">
                      {userData && !userData.data.payment_btc && (
                        <a
                          data-toggle="collapse"
                          href="#btc"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapse1"
                        >
                          <img src={btcImg} alt="" style={{ marginTop: 8 }} />
                        </a>
                      )}
                      {userData && !userData.data.payment_eth && (
                        <a
                          data-toggle="collapse"
                          href="#eth"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapse2"
                        >
                          <img
                            src={ethImg}
                            alt=""
                            style={{
                              marginTop: 8,
                              background: "#c4c1c1",
                              borderRadius: "50%",
                            }}
                          />
                        </a>
                      )}
                      {userData && !userData.data.payment_lydia && (
                        <a
                          data-toggle="collapse"
                          href="#lydia"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapse3"
                        >
                          <img
                            src={lydiaImg}
                            alt=""
                            style={{ height: 83, width: 82, marginTop: 8 }}
                          />
                        </a>
                      )}
                      {userData && !userData.data.payment_zelle && (
                        <a
                          data-toggle="collapse"
                          href="#zelle"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapse1"
                        >
                          <img
                            src={zelleImg}
                            alt=""
                            style={{
                              // width: "90px",
                              borderRadius: "50%",
                              marginTop: 8,
                            }}
                          />
                        </a>
                      )}
                      {userData && !userData.data.payment_venmo && (
                        <a
                          data-toggle="collapse"
                          href="#vimeo"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapse1"
                        >
                          <img
                            src={viemoImg}
                            alt=""
                            style={{
                              width: "60px",
                              borderRadius: "50%",
                              marginTop: 8,
                            }}
                          />
                        </a>
                      )}
                      {userData && !userData.data.payment_pay_pal && (
                        <a
                          data-toggle="collapse"
                          href="#paypal"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapse1"
                        >
                          <img
                            src={paypaLImg}
                            alt=""
                            style={{
                              borderRadius: "50%",
                              width: "54px",
                              height: "58px",
                              marginTop: 8,
                              background: "#c4c1c1",
                            }}
                          />
                        </a>
                      )}
                      {userData && !userData.data.payment_chime && (
                        <a
                          data-toggle="collapse"
                          href="#chime"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapse1"
                        >
                          <img
                            src={chimeImg}
                            alt=""
                            style={{
                              borderRadius: "50%",
                              width: "53px",
                              height: "57px",
                              marginTop: 8,
                            }}
                          />
                        </a>
                      )}
                      {userData && !userData.data.payment_cash_app && (
                        <a
                          data-toggle="collapse"
                          href="#cashapp"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapse1"
                        >
                          <img
                            src={cashAppImg}
                            alt=""
                            style={{
                              borderRadius: "50%",
                              width: "50px",
                              height: "55px",
                              marginTop: 8,
                            }}
                          />
                        </a>
                      )}
                    </div>
                  </div>

                  <div
                    class="add_new_payment collapse"
                    id="btc"
                    data-parent="#accordion"
                  >
                    <div class="input_field">
                      <label>What’s your BTC address?</label>
                      <div class="input_phone_field">
                        <input
                          type="text"
                          value={btc}
                          onChange={({ target }) => setBtc(target.value)}
                          placeholder="Your BTC address"
                        />
                      </div>
                    </div>
                    <div class="add_payment_method_btn">
                      <button onClick={() => handleSubmit(btc, "payment_btc")}>
                        Add
                      </button>
                    </div>
                  </div>

                  <div
                    class="add_new_payment collapse"
                    id="eth"
                    data-parent="#accordion"
                  >
                    <div class="input_field">
                      <label>What’s your ETH address?</label>
                      <div class="input_phone_field">
                        <input
                          type="text"
                          value={eth}
                          onChange={({ target }) => setEth(target.value)}
                          placeholder="Your ETH address"
                        />
                      </div>
                    </div>
                    <div class="add_payment_method_btn">
                      <button onClick={() => handleSubmit(eth, "payment_eth")}>
                        Add
                      </button>
                    </div>
                  </div>
                  <div
                    class="add_new_payment collapse"
                    id="lydia"
                    data-parent="#accordion"
                  >
                    <div class="input_field">
                      <label>What’s your Lydia phone number?</label>
                      <div class="input_phone_field">
                        <input
                          type="text"
                          value={lydia}
                          onChange={({ target }) => setLydia(target.value)}
                          placeholder="0123456789"
                        />
                      </div>
                    </div>
                    <div class="add_payment_method_btn">
                      <button onClick={() => handleSubmit(lydia, "payment_lydia")}>
                        Add
                      </button>
                    </div>
                  </div>
                  <div
                    class="add_new_payment collapse"
                    id="paypal"
                    data-parent="#accordion"
                  >
                    <div class="input_field">
                      <label>What’s your paypal.me username?</label>
                      <div class="input_phone_field">
                        <input
                          type="text"
                          value={paypal}
                          onChange={({ target }) => setPaypal(target.value)}
                          placeholder="janedoe"
                        />
                      </div>
                    </div>
                    <div class="add_payment_method_btn">
                      <button onClick={() => handleSubmit(paypal, "payment_pay_pal")}>
                        Add
                      </button>
                    </div>
                  </div>
                  <div
                    class="add_new_payment collapse"
                    id="chime"
                    data-parent="#accordion"
                  >
                    <div class="input_field">
                      <label>What’s your Chime email or phone number?</label>
                      <div class="input_phone_field">
                        <input
                          type="text"
                          value={chime}
                          onChange={({ target }) => setChime(target.value)}
                          placeholder="jane@example.com or 123-456-7890"
                        />
                      </div>
                    </div>
                    <div class="add_payment_method_btn">
                      <button onClick={() => handleSubmit(chime, "payment_chime")}>
                        Add
                      </button>
                    </div>
                  </div>

                  <div
                    class="add_new_payment collapse"
                    id="vimeo"
                    data-parent="#accordion"
                  >
                    <div class="input_field">
                      <label>What’s your Venmo username?</label>
                      <div class="input_phone_field">
                        <input
                          type="text"
                          value={vimeo}
                          onChange={({ target }) => setVimeo(target.value)}
                          placeholder="@Jane-Doe"
                        />
                      </div>
                    </div>
                    <div class="add_payment_method_btn">
                      <button onClick={() => handleSubmit(vimeo, "payment_venmo")}>
                        Add
                      </button>
                    </div>
                  </div>
                  <div
                    class="add_new_payment collapse"
                    id="zelle"
                    data-parent="#accordion"
                  >
                    <div class="input_field">
                      <label>What’s your Zelle email or phone number?</label>
                      <div class="input_phone_field">
                        <input
                          type="text"
                          value={zelle}
                          onChange={({ target }) => setZelle(target.value)}
                          placeholder="jane@example.com or 123-456-7890"
                        />
                      </div>
                    </div>
                    <div class="add_payment_method_btn">
                      <button onClick={() => handleSubmit(zelle, "payment_zelle")}>
                        Add
                      </button>
                    </div>
                  </div>
                  <div
                    class="add_new_payment collapse"
                    id="cashapp"
                    data-parent="#accordion"
                  >
                    <div class="input_field">
                      <label>What’s your Cashtag?</label>
                      <div class="input_phone_field">
                        <input
                          type="text"
                          value={cashapp}
                          onChange={({ target }) => setCashapp(target.value)}
                          placeholder="$JaneDoe"
                        />
                      </div>
                    </div>
                    <div class="add_payment_method_btn">
                      <button onClick={() => handleSubmit(cashapp, "payment_cash_app")}>
                        Add
                      </button>
                    </div>
                  </div>
                </div>

                <div class="payment_conditions">
                  <span>
                    Pro Tips: Give More payment option to your customers to
                    maximize tips!
                  </span>
                  <h3>
                    Don't have Venmo?{" "}
                    <a
                      href="https://get.venmo.com/sdtDiaRchgb"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sign up here!
                    </a>
                  </h3>
                  <h3>
                    Don't have Cashapp?{" "}
                    <a
                      href="https://cash.app/app/GFKTNCQ"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sign up here!
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* {localStorage.getItem("isMasterAdmin") !== "2" && <BottomNav index={3} />} */}
    </div>
  );
};

export default PaymentMethodsScreen;
