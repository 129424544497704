import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { Card, Col, Image, Row } from "react-bootstrap";

import { selectBusiness } from "../redux/actions";

const Listing = (props) => {
  const { results, type } = props;
  const history = useHistory();

  const handleClick = (e, context) => {
    e.preventDefault();
    props.selectBusiness(context.place_id);

    type === 0
      ? history.push(`/b/${context.place_id}`)
      : history.push(`/main_b/${context.place_id}`);
  };

  return (
    <>
      <div className="mt-4" style={{ height: "36vh", overflowY: "auto" }}>
        <Row className="justify-content-center m-auto">
          <Col className="col-lg-6 col-md-10 col-sm-12 col-12">
            {results.length >= 1 ? (
              <h6 className="text-muted p-2">Search Results</h6>
            ) : null}
            {results.map((result) => {
              return (
                <Card
                  key={result.place_id}
                  onClick={(e) => handleClick(e, result)}
                  className="pointer-cursor"
                >
                  <Card.Body>
                    <Row>
                      <Col className="col-sm-2 col-md-2 col-3 d-flex flex-row align-items-center">
                        <Image alt="" src={result.icon} fluid />
                      </Col>
                      <Col className="col-sm-10 col-md-10 col-9 d-flex flex-row align-items-center">
                        <div>
                          <h5>{result.name}</h5>
                          <p className="small">{result.formatted_address}</p>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              );
            })}
          </Col>
        </Row>
      </div>
    </>
  );
};

Listing.propTypes = {
  selectBusiness: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(PropTypes.object),
};

Listing.defaultProps = {
  results: [],
};

const mapStateToProps = (state) => {
  return {
    results: state.workplaceReducer.searchResult,
  };
};

export default connect(mapStateToProps, { selectBusiness })(Listing);
