import React from "react";
import { Link } from "react-router-dom";
import "./Congratulations.css";
import congratulations from "../../assets/images/congratulations.png";

const CongratulationsScreen = () => {
  return (
    <div className="main-body">
      <section class="login_section">
        <div class="login_container">
          <div class="login_box">
            <div class="congratulation_form">
              <figure>
                <img src={congratulations} alt="" />
              </figure>
              <h3>Congratulations!</h3>
              <span>
                You're all set now. Tell your customers they can find you on
                teepmee.com!
              </span>
              <Link to="/paymentMethods">
                <button>Congratulations</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CongratulationsScreen;
