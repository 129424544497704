import React from "react";

import icon from "../../assets/images/bulb.png";
import { Col, Row } from "react-bootstrap";

const MobileBanner = () => {
  function handleClick() {
    if (localStorage.getItem("token")) {
      document.getElementById("mobileBanner").href =
        "https://teepmee.app.link/download";
    } else {
      document.getElementById("mobileBanner").href = "/";
    }
  }

  return (
    <>
      <a href="" id="mobileBanner" onClick={handleClick}>
        <Row className="secondary-background m-auto">
          <Col className="col-sm-2 col-2 d-flex flex-row align-items-center pl-2 pt-3 pb-3">
            <img alt="" src={icon} />
          </Col>
          <Col className="col-sm-9 col-9 small d-flex flex-row align-items-center">
            <div>
              <div className="font-weight-bold">
                Do you want to start receiving more tips?
              </div>
              <div>Create an account now!</div>
            </div>
          </Col>
          <Col className="col-sm-1 col-1 accent-color d-flex flex-row align-items-center justify-content-center">
            <a href="/">
              <i className="fa fa-chevron-right" />
            </a>
          </Col>
        </Row>
      </a>
    </>
  );
};

export default MobileBanner;
