import {Field, Form, Formik} from "formik";
import React from "react";
import {Link} from "react-router-dom";

const VerficationScreen = ({initialValues, handleSubmit, error}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <div className="main-body">
        <section class="login_section">
          <div class="login_container">
            <div class="login_box">
              <div class="login_form">
                <h5>Welcome To</h5>
                <div class="company_logo">
                  <a href="#">
                    <span>teep</span>
                    <i class="fas fa-circle"></i>
                    <span>mee</span>
                  </a>
                </div>
                <Form>
                  <div class="login_fields">
                    <div class="input_field">
                      <label>Enter Code</label>
                      <div class="input_phone_field">
                        <Field
                          name="code"
                          // type="number"
                          placeholder="Verification Code"
                        />
                      </div>
                    </div>
                    {error.data && (
                      <p style={{color: "red"}}>Code is incorrect.</p>
                    )}
                    <div class="terms_and_conditions">
                      <div class="resend_code">
                        <Link to="/">Resend Code</Link>
                      </div>
                      <p>By using this app,you agree to our</p>
                      <a href="#">Terms and conditions</a>
                      <a href="#">Privacy policy</a>
                    </div>

                    <div class="submit_btn">
                      <button type="submit">Sign in</button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Formik>
  );
};

export default VerficationScreen;
