import React, { useEffect, useState } from "react";
import PaymentMethodsScreen from "./PaymentMethodsScreen";
import useApi from "../../hooks/useApi";
import * as api from "../../api/api";

const PaymentMethod = () => {
  const userProfile = useApi(api.getUserProfile);
  const deletePay = useApi(api.deletePayment);
  const { request } = useApi(api.addPayment);
  const [btc, setBtc] = useState("");
  const [eth, setEth] = useState("");
  const [lydia, setLydia] = useState("");
  const [zelle, setZelle] = useState("");
  const [vimeo, setVimeo] = useState("");
  const [paypal, setPaypal] = useState("");
  const [chime, setChime] = useState("");
  const [cashapp, setCashapp] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        await userProfile.request();
      } catch (_) {}
    }
    fetchData();
  }, []);

  // console.log("userdata", userProfile.data);

  async function handleSubmit(value, type) {
    try {
      const data = await request({ type, value });
      // console.log("dsata", data);
      data && window.location.reload();
    } catch (_) {}
  }
  async function deletePayment(type) {
    try {
      const data = await deletePay.request(type);

      data && window.location.reload();
    } catch (_) {}
  }
  // console.log("data", data);
  return (
    <PaymentMethodsScreen
      handleSubmit={handleSubmit}
      btc={btc}
      setBtc={setBtc}
      eth={eth}
      setEth={setEth}
      lydia={lydia}
      setLydia={setLydia}
      zelle={zelle}
      setZelle={setZelle}
      vimeo={vimeo}
      setVimeo={setVimeo}
      paypal={paypal}
      setPaypal={setPaypal}
      chime={chime}
      setChime={setChime}
      setCashapp={setCashapp}
      cashapp={cashapp}
      userData={userProfile.data}
      deletePayment={deletePayment}
    />
  );
};

export default PaymentMethod;
