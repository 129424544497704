import { Form, Field, Formik } from "formik";
import React, { useState } from "react";
import "./AddJob.css";

const AddJobScreen = ({ data, initialValues, handleSubmit }) => {
  const [seeMore, setSeeMore] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  let isMobile = width <= 768 ? 5 : 10;
  function handleSeeMore() {
    setSeeMore(true);
  }

  const job = data.map(
    (position, index) =>
      index < isMobile && (
        <label>
          <Field type="checkbox" name="positions" value={position.name} />
          <span>{position.name}</span>
        </label>
      )
  );
  job.push(
    <label>
      <span
        style={{
          color: "orange",
          backgroundColor: "#fcedd8",
        }}
        onClick={handleSeeMore}
      >
        See More +
      </span>
    </label>
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      <section class="login_section">
        <div class="job_container">
          <div class="login_box">
            <div class="greate_what_job_form">
              <h3>Great What's Your Job?</h3>
              <span>Filters:</span>
              <Form>
                <div class="greate_what_job_data_fields">
                  <div class="greate_what_job_checkbox_field">
                    <div class="greate_what_job_checkbox">
                      {!seeMore ? (
                        <>
                          {job}
                          {/* <label>
                            <span
                              style={{
                                color: "orange",
                                backgroundColor: "#fcedd8",
                              }}
                              onClick={handleSeeMore}
                            >
                              See More +
                            </span>
                          </label> */}
                        </>
                      ) : (
                        data.map((position) => (
                          <label>
                            <Field
                              type="checkbox"
                              name="positions"
                              value={position.name}
                            />
                            <span>{position.name}</span>
                          </label>
                        ))
                      )}
                    </div>
                  </div>

                  <div class="input_field">
                    <label>Others:</label>
                    <div class="input_phone_field">
                      <Field name="other" placeholder="Type Job" />
                    </div>
                  </div>
                  <div class="submit_btn">
                    <button type="submit">Done</button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </Formik>
  );
};

export default AddJobScreen;
