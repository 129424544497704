const initialState = {
  businessId: "",
};

export const linkReducer = (state = initialState, action) => {
  switch (action.type) {
    case "link/saveId":
      return action.payload;

    default:
      return state;
  }
};
