import React, { useState } from "react";
import { Modal } from "antd";
import "antd/dist/antd.css";
import zele from "../assets/images/zele.png";
import "./Modal.css";
import { CopyToClipboard } from "react-copy-to-clipboard";

const TipModal = ({ profile, show, setShow }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleCancel = () => {
    setShow(false);
    setIsModalVisible(false);
    setCopied(false);
  };

  return (
    <div>
      <Modal
        visible={show}
        centered
        footer
        width="340px"
        bodyStyle={{
          height: "250px",
        }}
        closable={false}
      >
        <p
          className=""
          style={{ margin: 10, marginTop: 30, fontSize: "1.3rem" }}
        >
          Thank you. Please send your Zelle to
        </p>
        <div className="copy-area">
          <img src={zele} alt="" className="icon zele" />
          <span className="copy-profile">{profile}</span>
          <CopyToClipboard text={profile} onCopy={() => setCopied(true)}>
            <i class="fas fa-clone copy-icon"></i>
          </CopyToClipboard>
        </div>
        {copied && (
          <p style={{ marginTop: "0px", color: "red", textAlign: "center" }}>
            Copied! Just paste it in Zelle!
          </p>
        )}
        <div style={{ textAlign: "right", width: "95%", marginTop: 20 }}>
          <button className="close-btn" onClick={handleCancel}>
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default TipModal;
