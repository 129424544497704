import { Field, Form, Formik } from "formik";
import React from "react";

const ProfileNameScreen = ({ initialValues, handleSubmit }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <div className="main-body">
        <section class="login_section">
          <div class="login_container">
            <div class="login_box">
              <div class="login_form">
                <h5>Welcome To</h5>
                <div class="company_logo">
                  <a href="#">
                    <span>teep</span>
                    <i class="fas fa-circle"></i>
                    <span>me</span>
                  </a>
                </div>
                <Form>
                  <div class="login_fields">
                    <div class="input_field">
                      <label>Whats Your Name?</label>
                      <div class="input_phone_field">
                        <Field name="name" type="text" placeholder="" />
                      </div>
                    </div>

                    <div class="conditions">
                      <span>
                        Remember,put the name as it Appears on your name tag so
                        customer can find you easily!
                      </span>
                    </div>

                    <div class="submit_btn">
                      <button type="submit">Next</button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Formik>
  );
};

export default ProfileNameScreen;
