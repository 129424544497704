import {useState} from "react";
import nProgress from "nprogress";

export const PHONE_NO_ERROR = "Phone number already in use.";

export default function useApi(apiFunc, {hasCatchError = false} = {}) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState("");

  async function request(...params) {
    setIsLoading(true);
    nProgress.start();
    try {
      const res = await apiFunc(...params);
      setIsLoading(false);
      setData(res.data);
      setError("");
      nProgress.done();
      return res;
    } catch (err) {
      console.log({err});
      setIsLoading(false);

      if (err.response) {
        if (err.response?.data?.message == "invalid_phone_duplicated") {
          setError(PHONE_NO_ERROR);
        } else {
          setError(
            err.response?.data?.message?.includes("invalid_username_duplicated")
              ? "Username already exists"
              : "Something went wrong"
          );
        }
        setData(err.response?.data?.data || null);
      } else {
        const errRes = {
          status: 503,
          data: {
            message: "Network Error. Please check your connection ..",
            errors: [],
          },
        };
        err.response = errRes;
        setError(err.response);
      }

      nProgress.done();

      if (hasCatchError) {
        throw err;
      }
    }
  }

  return {isLoading, data, error, request};
}
