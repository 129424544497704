import React, { useState } from "react";

import { Link } from "react-router-dom";
import zeleIcon from "../assets/images/zele.png";
import eth from "../assets/images/eth.png";
import lydia from "../assets/images/lydia.png";
import btc from "../assets/images/btc.png";
import { Col, Row, Badge } from "react-bootstrap";
import ChimeModal from "./ChimeModal";
import TipModal from "./Modal";
import CryptoModal from "./CryptoModal";

const PaymentButton = ({ type, icon, text, handleClick }) => {
  return (
    <Badge pill className={`${type} p-3 w-100 font-lg`}>
      <a
        onClick={handleClick}
        href="/"
        className="d-flex justify-content-between"
      >
        <i className={`${icon} font-weight-bold`} />
        <span className="font-weight-bold">{text}</span>
        <i className="fa fa-chevron-right" />
      </a>
    </Badge>
  );
};

const Reward = (props) => {
  const { employeeProfile } = props;
  // console.log("profile", employeeProfile);
  // const [show, setShow] = useState(false);
  const [showChime, setShowChime] = useState(false);
  const [showZele, setShowZele] = useState(false);
  const [showBtc, setShowBtc] = useState(false);
  const [showEth, setShowEth] = useState(false);
  const [showLydia, setShowLydia] = useState(false);

  const handleClick = (e, method, target = "/") => {
    e.preventDefault();
    window.analytics.track("tipGiven", {
      tippingMethod: method,
      tippedUser: employeeProfile.id,
    });
    window.location.href = target;
  };

  return (
    <>
      <Row className="justify-content-center">
        <Col className="col-lg-10 col-md-10 col-sm-11 col-11">
          {employeeProfile.payment_venmo && (
            <PaymentButton
              type="btn-venmo"
              icon="fab fa-vimeo-v"
              text="Send with Venmo"
              handleClick={(e) => {
                handleClick(
                  e,
                  "Venmo",
                  "https://venmo.com/" +
                    employeeProfile.payment_venmo +
                    "?txn=pay&note=from%20TeepMee"
                );
              }}
            />
          )}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="col-lg-10 col-md-10 col-sm-11 col-11">
          {employeeProfile.payment_cash_app && (
            <PaymentButton
              type="btn-cashapp"
              icon="fa fa-dollar-sign"
              text="Send with Cashapp"
              handleClick={(e) => {
                handleClick(
                  e,
                  "Cashapp",
                  "https://cash.app/$" + employeeProfile.payment_cash_app
                );
              }}
            />
          )}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="col-lg-10 col-md-10 col-sm-11 col-11">
          {employeeProfile.payment_pay_pal && (
            <PaymentButton
              type="btn-paypal"
              icon="fab fa-paypal"
              text="Send with Paypal"
              handleClick={(e) => {
                handleClick(
                  e,
                  "Paypal",
                  "https://paypal.me/" + employeeProfile.payment_pay_pal
                );
              }}
            />
          )}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="col-lg-10 col-md-10 col-sm-11 col-11">
          {employeeProfile.payment_zelle && (
            <div
              onClick={() => setShowZele(true)}
              style={{ cursor: "pointer" }}
            >
              <Badge
                pill
                className="btn-zelle p-3 w-100 font-lg d-flex justify-content-between"
              >
                <img alt="zele logo" src={zeleIcon} />
                <span className="font-weight-bold">Send with Zelle</span>
                <i className="fa fa-chevron-right" />
              </Badge>
            </div>
          )}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="col-lg-10 col-md-10 col-sm-11 col-11">
          {employeeProfile.payment_chime && (
            <div
              onClick={() => setShowChime(true)}
              style={{ cursor: "pointer" }}
            >
              <Badge
                pill
                className={`btn-chime p-3 w-100 font-lg d-flex justify-content-between`}
              >
                <i className={`fab fa-cuttlefish font-weight-bold`} />
                <span className="font-weight-bold">Send with Chime</span>
                <i className="fa fa-chevron-right" />
              </Badge>
            </div>
          )}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="col-lg-10 col-md-10 col-sm-11 col-11">
          {employeeProfile.payment_other && (
            <PaymentButton
              type="btn-other"
              icon="fa fa-question"
              text="Other"
              handleClick={(e) => {
                handleClick(e, "Other", employeeProfile.payment_other);
              }}
            />
          )}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="col-lg-10 col-md-10 col-sm-11 col-11">
          {employeeProfile.payment_btc && (
            <div onClick={() => setShowBtc(true)} style={{ cursor: "pointer" }}>
              <Badge
                pill
                className="btn-btc p-3 w-100 font-lg d-flex justify-content-between"
              >
                {/* <img alt="zele logo" src={btc} width={20} height={15} /> */}
                <i class="fab fa-btc"></i>
                <span className="font-weight-bold">Send with BTC</span>
                <i className="fa fa-chevron-right" />
              </Badge>
            </div>
          )}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="col-lg-10 col-md-10 col-sm-11 col-11">
          {employeeProfile.payment_eth && (
            <div onClick={() => setShowEth(true)} style={{ cursor: "pointer" }}>
              <Badge
                pill
                className="btn-eth p-3 w-100 font-lg d-flex justify-content-between"
              >
                {/* <img alt="zele logo" src={eth} width={20} height={20} /> */}
                <i
                  class="fab fa-ethereum"
                  style={{ color: "black", fontSize: "1rem" }}
                ></i>
                <span className="font-weight-bold">Send with ETH</span>
                <i className="fa fa-chevron-right" />
              </Badge>
            </div>
          )}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="col-lg-10 col-md-10 col-sm-11 col-11">
          {employeeProfile.payment_lydia && (
            <div
              onClick={() => setShowLydia(true)}
              style={{ cursor: "pointer" }}
            >
              <Badge
                pill
                className="btn-lydia p-3 w-100 font-lg d-flex justify-content-between"
              >
                <img
                  alt="zele logo"
                  src={lydia}
                  width={16}
                  height={16}
                  style={{ borderRadius: "50%" }}
                />
                <span className="font-weight-bold">Send with Lydia</span>
                <i className="fa fa-chevron-right" />
              </Badge>
            </div>
          )}
        </Col>
      </Row>
      <Row className="justify-content-center pt-3">
        <Col className="col-lg-10 col-md-10 col-sm-11 col-11">
          <Link to="/" className="btn btn-warning btn-lg w-100">
            New search?
          </Link>
        </Col>
      </Row>
      {localStorage.getItem("isMasterAdmin") === "2" && (
        <>
          <Row className="justify-content-center pt-3">
            <Col className="col-lg-10 col-md-10 col-sm-11 col-11">
              <Link
                to={`/edit-profile/${employeeProfile.id}`}
                className="btn btn-warning btn-lg w-100"
              >
                Profile Edit
              </Link>
            </Col>
          </Row>
          <Row className="justify-content-center pt-3">
            <Col className="col-lg-10 col-md-10 col-sm-11 col-11">
              <Link
                to={`/edit-payment/${employeeProfile.id}`}
                className="btn btn-warning btn-lg w-100"
              >
                Payment Edit
              </Link>
            </Col>
          </Row>
        </>
      )}
      <ChimeModal
        profile={employeeProfile.payment_chime}
        show={showChime}
        setShow={setShowChime}
      />
      <TipModal
        profile={employeeProfile.payment_zelle}
        show={showZele}
        setShow={setShowZele}
      />
      <CryptoModal
        show={showBtc}
        setShow={setShowBtc}
        type="BTC"
        image={btc}
        profile={employeeProfile.payment_btc}
      />
      <CryptoModal
        show={showLydia}
        setShow={setShowLydia}
        type="Lydia"
        image={lydia}
        lydia={true}
        profile={employeeProfile.payment_lydia}
      />
      <CryptoModal
        show={showEth}
        setShow={setShowEth}
        type="ETH"
        image={eth}
        profile={employeeProfile.payment_eth}
      />
    </>
  );
};

export default Reward;
