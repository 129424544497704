import React, {useEffect, useState} from "react";

import algoliasearch from "algoliasearch/lite";
import PropTypes from "prop-types";
import {Link, useHistory} from "react-router-dom";

import {Button, Card, Col, Row} from "react-bootstrap";
import DeleteModal from "./Admin/DeleteModal/deleteModal";
import {adminGetUsers, jobDelete} from "../api/api";
import useApi from "../hooks/useApi";
import axios from "axios";
import managerIcon from "../assets/images/manager.jpeg";

const EmployeesList = (props) => {
  const {request, data} = useApi(jobDelete);
  const [employees, setEmployees] = useState(null);
  const [tags, setTags] = useState([]);
  const [render, setRender] = useState(true);
  const [filteredArray, setFilteredArray] = useState([]);
  const [color, setColor] = useState([]);
  const [isTagActive, setIsTagActive] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const history = useHistory();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [loading, setLoading] = useState(true)

  // let color = [];

  useEffect(() => {
    if (!props.placeId) {
      return;
    }
    if (localStorage.getItem("isMasterAdmin") == "2" || props?.isManager) {
      getEmployeesList();
    } else {
      const searchClient = algoliasearch(
        process.env.REACT_APP_ALGOLIA_ID,
        process.env.REACT_APP_ALGOLIA_KEY
      );
      const index = searchClient.initIndex(process.env.REACT_APP_USER_INDEX);
      index
        .search(nameSearch, {
          hitsPerPage: 1000,
          facetFilters: [[`workplace.placeId:${props.placeId}`]],
        })
        .then(({hits}) => {
          let tempTags = new Set();
          setEmployees(hits);
          hits.map((hit) => {
            hit.positions.map((position) => {
              tempTags.add(position);
            });
            // setTags((prev) => [...prev, ...hit.positions]);
            setIsTagActive((prev) => [...prev, false]);
          });
          setColor([...tempTags].map((item) => "outline-warning"));
          setTags([...tempTags]);
        });
      setLoading(false)
    }
  }, [props.placeId, nameSearch, props?.isManager]);

  // color.length > 0 && console.log("color", color);

  const getEmployeesList = async () => {
    try {
      let tempTags = new Set();
      const {data} = await adminGetUsers(props.placeId);
      setEmployees(data?.data);
      data?.data.map((item) => {
        item.positions.map((position) => {
          tempTags.add(position);
        });
        // setTags((prev) => [...prev, ...hit.positions]);
        setIsTagActive((prev) => [...prev, false]);
      });
      setColor([...tempTags].map((item) => "outline-warning"));
      setTags([...tempTags]);
      // console.log('emp list response',res);
    } catch (error) {
      setEmployees([])
      console.log("employees list error", error);
    }
    setLoading(false)
  };

  const handleTagSearch = (tag, i) => {
    // setColor[i] = true;
    let newArr = [...color];
    let tagsArray = [...isTagActive];
    tagsArray[i] = !tagsArray[i];
    setIsTagActive(tagsArray);
    // setIsTagActive((prev, i) => console.log("i", i));
    tagsArray[i] ? (newArr[i] = "warning") : (newArr[i] = "outline-warning");

    setColor(newArr);
    employees && employees.map((employee) =>
      tagsArray[i]
        ? employee.positions.includes(tag) &&
          setFilteredArray((prev) => [...new Set([...prev, employee])])
        : setFilteredArray((prev) =>
            prev.filter((fil) => !fil.positions.includes(tag))
          )
    );
  };
  const handleNameSearch = ({target}) => {
    setNameSearch(target.value);
    setFilteredArray([]);
  };

  const handleClick = (e, context) => {
    e.preventDefault();
    const userName = context.user.user_name
      ? context.user.user_name
      : context.user.userName;
    history.push(`/u/${userName}`);
  };

  const onImgLoad = ({target: img}, employee) => {
    if (img.offsetHeight > img.offsetWidth) {
      employee.user.class = "portrait-employee-img";
      setRender(!render);
    }
  };

  const handleModal = (id) => {
    setDeleteModal(true);
    setDeleteId(id);
  };

  const handleDelete = async () => {
    if (deleteId) {
      try {
        await request(deleteId);
        window.location.reload();
      } catch (error) {
        console.log("error", error.response);
      }
    }
  };


  return (
    <>
      <Row>
        <div>
          <center>
            <Link to={`/workplace?businessId=${props.placeId}`}>
              Work here? Create an account and get $tips!
            </Link>
            <div
              style={{
                borderTop: "1px solid black",
                width: "40%",
                height: 1,
                margin: "0 auto",
              }}
            ></div>
            <p>
              Report a problem:
              <Link
                to="#"
                onClick={(e) => {
                  window.location.href = "mailto:contact@teepmee.com";
                  e.preventDefault();
                }}
              >
                contact us
              </Link>
            </p>
          </center>
          {(employees && employees.length<1) && (
            <Row className="justify-content-center pt-3">
              <Col className="col-lg-5 col-md-5 col-sm-6 col-8">
                <a
                  href={
                    "https://teepmee.com/workplace?businessId=" + props.placeId
                  }
                  className="btn btn-warning btn-lg w-100"
                >
                  Let's go!
                </a>
              </Col>
            </Row>
          )}
          <br />
        </div>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          margin: "auto",
          width: "85%",
        }}
      >
        {tags.length > 0 &&
          tags.map((tag, i) => (
            <Button
              key={i}
              variant={`${color[i]}`}
              style={{
                borderRadius: "30px",
                marginLeft: "5px",
                marginBottom: "5px",
                fontSize: "0.9rem",
              }}
              onClick={() => handleTagSearch(tag, i)}
            >
              {tag}
            </Button>
          ))}
      </div>
      {tags.length > 0 && (
        <div style={{display: "flex", justifyContent: "center"}}>
          <input
            name="nameSearch"
            value={nameSearch}
            onChange={handleNameSearch}
            placeholder="Search Name"
            style={{width: "80%", marginTop: "10px"}}
          />
        </div>
      )}
      <Row className="justify-content-center mt-4">
        <Col className="col-lg-10 col-md-10 col-sm-10 col-10">
          {employees && employees?.length >= 1 ? (
            <h6 className="text-muted">Search Results</h6>
          ) : null}
          {filteredArray.length < 1
            ? employees && employees?.map((employee) => {
                return (
                  <Card key={employee.user.id} className="pointer-cursor">
                    <Card.Body>
                      <Row>
                        <Col
                          xs={5}
                          md={3}
                          lg={3}
                          className="d-flex flex-row align-items-center justify-content-between"
                          onClick={(e) => handleClick(e, employee)}
                        >
                          <div style={{position: "relative"}}>
                            <div className="employee-img-container">
                              <img
                                onLoad={(e) => onImgLoad(e, employee)}
                                className={`${
                                  employee.user.class
                                    ? "portrait-employee-img"
                                    : "landscape-employee-img"
                                }`}
                                alt=""
                                src={
                                  employee.user.photo_url ||
                                  employee.user.photoUrl
                                }
                              />
                            </div>
                            {employee.is_admin && employee?.user?.role == 1 && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  right: -10,
                                }}
                              >
                                <img
                                  style={{width: 40, height: 40}}
                                  alt=""
                                  src={managerIcon}
                                />
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col
                          xs={5}
                          md={8}
                          lg={8}
                          className="d-flex flex-row align-items-center"
                          onClick={(e) => handleClick(e, employee)}
                        >
                          <div>
                            <h5>{employee.user.name}</h5>
                            <p className="small">
                              {employee.user.job_title ||
                                employee.user.jobTitle}
                            </p>
                          </div>
                        </Col>
                        {(props?.isManager ||
                          localStorage.getItem("isMasterAdmin") == "2") &&
                          localStorage.getItem("userId") !=
                            employee.user.id && (
                            <Col
                              xs={1}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "red",
                              }}
                            >
                              <div>
                                <i
                                  className="fas fa-trash-alt"
                                  onClick={() => handleModal(employee.id)}
                                />
                              </div>
                            </Col>
                          )}
                      </Row>
                    </Card.Body>
                  </Card>
                );
              })
            : filteredArray.map((employee) => {
                return (
                  <Card
                    key={employee.user.id}
                    onClick={(e) => handleClick(e, employee)}
                    className="pointer-cursor"
                  >
                    <Card.Body>
                      <Row>
                        <Col
                          xs={4}
                          md={3}
                          lg={3}
                          className="d-flex flex-row align-items-center"
                        >
                          <div style={{position: "relative"}}>
                            <div className="employee-img-container">
                              <img
                                onLoad={(e) => onImgLoad(e, employee)}
                                className={`${
                                  employee.user.class
                                    ? "portrait-employee-img"
                                    : "landscape-employee-img"
                                }`}
                                alt=""
                                src={
                                  employee.user.photo_url ||
                                  employee.user.photoUrl
                                }
                              />
                            </div>
                            {employee.is_admin && employee?.user?.role == 1 && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  right: -10,
                                }}
                              >
                                <img
                                  style={{width: 40, height: 40}}
                                  alt=""
                                  src={managerIcon}
                                />
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col
                          xs={7}
                          md={8}
                          lg={8}
                          className="d-flex flex-row align-items-center"
                        >
                          <div>
                            <h5>{employee.user.name}</h5>
                            <p className="small">
                              {employee.user.job_title ||
                                employee.user.jobTitle}
                            </p>
                          </div>
                        </Col>
                        {(props?.isManager ||
                          localStorage.getItem("isMasterAdmin") == "2") &&
                          localStorage.getItem("userId") !=
                            employee.user.id && (
                            <Col
                              xs={1}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "red",
                              }}
                            >
                              <div>
                                <i
                                  className="fas fa-trash-alt"
                                  onClick={() => handleModal(employee.id)}
                                />
                              </div>
                            </Col>
                          )}
                      </Row>
                    </Card.Body>
                  </Card>
                );
              })}
        </Col>
      </Row>
      <DeleteModal
        show={deleteModal}
        setShow={setDeleteModal}
        handleDelete={handleDelete}
      />
    </>
  );
};

EmployeesList.defaultProps = {
  placeId: "",
};

EmployeesList.propTypes = {
  placeId: PropTypes.string,
};

export default EmployeesList;
