import React, {useEffect, useState} from "react";

import {Helmet} from "react-helmet";
import {Link, useHistory} from "react-router-dom";

import {Col, Jumbotron, Row} from "react-bootstrap";

// import { Reward } from "./index";
import {connect} from "react-redux";
import PropTypes from "prop-types";
// import useApi from "../hooks/useApi";
// import BottomNav from "../BottomNav/BottomNav";
// import Sidebar from "../Sidebar/Sidebar";
import useApi from "../../../hooks/useApi";
import * as api from "../../../api/api";
import img from "../../../assets/images/img_avatar.png";
import "./style.css";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import axios from "axios";
import nProgress from "nprogress";
import {Upload} from "antd";
import AntdImgCrop from "antd-img-crop";

let initialValues = {
  name: "",
  user_name: "",
  job_title: "",
  description: "",
  phone: "",
  businessManager: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Missing Field"),
  user_name: Yup.string().required("Missing Field"),
});

const EditProfile = (props) => {
  const {data, request} = useApi(api.getProfile);
  const updateUser = useApi(api.adminUpdateUser);
  const [employeeProfile, setEmployeeProfile] = useState({});
  const [values, setValues] = useState({});
  const [imageClass, setImageClass] = useState("landscape-img");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [image, setImage] = useState("");
  const [previewImg, setPreviewImg] = useState("");
  const history = useHistory();

  const urlPath = window.location.href;
  const employeeID = urlPath.split("edit-profile/")[1];

  useEffect(() => {
    async function fetchData() {
      try {
        const {data} = await request(employeeID);
        initialValues = {...initialValues, ...data?.data};
        // initialValues.name = data.data?.name;
        // initialValues.user_name = data.data?.user_name;
        // initialValues.user_name = data.data?.user_name;
        // initialValues.title = data.data?.job_title;
        // initialValues.user_name = data.data?.user_name;
        // initialValues.user_name = data.data?.user_name;

        if (!data.data.job_title) initialValues.job_title = "";
        if (!data.data.description) initialValues.description = "";
        setValues({...values, ...initialValues});
      } catch (_) {}
    }
    fetchData();
  }, []);

  if (data && !data) {
    return <h1 className="text-center">Page not found</h1>;
  }

  const onImgLoad = ({target: img}) => {
    if (img.offsetHeight > img.offsetWidth) {
      setImageClass("portrait-img");
    }
  };

  const handleBackButton = () => {
    history.goBack();
  };
  const handleImage = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    const getBase64 = (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    };
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setPreviewImg(url);
        setImage(info.file.originFileObj);
      });
    }
  };
  const handleSubmit = async (values) => {
    if (values?.phone == data?.data?.phone) {
      delete values.phone;
    }
    try {
      const {data} = await updateUser.request(values);
      if (image) {
        try {
          nProgress.start();
          const formData = new FormData();
          formData.append("avatar", image);
          const response = await axios.post(
            `https://api.teepmee.com/admin/users/avatar/${employeeID}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("token"),
              },
            }
          );
        } catch (error) {
          nProgress.done();
          console.log(error.response);
        }
      }
      history.replace(`/u/${values?.user_name}`);
    } catch (error) {
      console.log(error);
    }
  };

  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <div className="abc">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <Form>
          {/* <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} /> */}
          <Helmet>
            <meta charSet="utf-8" />
            {data && <title>{`Teepmee - ${data.data.name}`}</title>}
          </Helmet>
          {data && (
            <div>
              <Jumbotron className="mb-0">
                <Row className="justify-content-center">
                  <Col className="col-lg-6 col-md-10 col-12 col-sm-12 d-flex justify-content-between">
                    <Link onClick={handleBackButton} className="btn bg-white">
                      <i className="fa fa-arrow-left" />
                    </Link>
                    {/* <Link to="" className="btn bg-white">
                  <i className="fa fa-share-alt" />
                </Link> */}
                  </Col>
                </Row>
              </Jumbotron>
              {employeeProfile ? (
                <>
                  <Row className="mt-lg-n5 mt-md-n5 mt-sm-n5 mt-n5 mr-0">
                    <Col className="col-lg-6 col-md-10 col-12 col-sm-12 mx-auto d-flex justify-content-center">
                      {/* <div className="image-container">
                        <img
                          onLoad={onImgLoad}
                          className={imageClass}
                          alt=""
                          src={data.data.photo_url}
                        />
                      </div> */}
                      <div class="input_figure_field">
                        <figure className="profile-figure">
                          {image ? (
                            <img src={previewImg} alt="" />
                          ) : (
                            <img
                              src={
                                data && data.data.photo_url
                                  ? data.data.photo_url
                                  : img
                              }
                              alt=""
                            />
                          )}
                          <div class="change_pic">
                            <AntdImgCrop
                              showGrid
                              rotationSlider
                              aspectSlider
                              showReset
                            >
                              <Upload
                                customRequest={dummyRequest}
                                onChange={handleImage}
                                showUploadList={false}
                                multiple={false}
                                maxCount={1}
                              >
                                <label class="camera_label">
                                  <i class="fas fa-camera"></i>
                                </label>
                              </Upload>
                            </AntdImgCrop>
                          </div>
                        </figure>
                      </div>
                    </Col>
                  </Row>
                  {/* <Row className="justify-content-center m-auto">
                <Col className="col-lg-6 col-md-10 col-12 col-sm-12 text-center">
                  <h2 className="profile-color">{data.data.name}</h2>
                  <p className="profile-color">
                    Username: {data.data.user_name}
                  </p>
                </Col>
              </Row> */}
                  <Row className="justify-content-center m-auto">
                    <Col className="col-lg-3 col-md-3  col-sm-5 ">
                      <p className="profile-color">Name</p>
                      <Field type="text" name="name" style={{width: "100%"}} />
                      <ErrorMessage
                        component="div"
                        name="name"
                        style={{color: "red"}}
                      />
                      <p className="profile-color">Username</p>
                      <Field type="text" name="user_name" />
                      <ErrorMessage
                        component="div"
                        name="user_name"
                        style={{color: "red"}}
                      />

                      <p className="profile-color">Title</p>
                      <Field type="text" name="job_title" />
                      <p className="profile-color">Phone Number (optional)</p>
                      <Field type="text" name="phone" />
                      {phoneNumberError && (
                        <p style={{color: "red"}}>{phoneNumberError}</p>
                      )}
                      <p className="profile-color">
                        Manager of Business (optional)
                      </p>
                      <Field name="businessManager" />

                      <p className="profile-color">Bio</p>

                      <Field
                        as="textarea"
                        className="bio"
                        name="description"
                        placeholder="Write few lines about yourself!"
                      ></Field>
                      {updateUser?.error && (
                        <p style={{color: "red"}}>{updateUser?.error}</p>
                      )}
                    </Col>
                  </Row>
                  {/* {!image && <p style={{ color: "red" }}>Image is required</p>} */}
                  <Row className="justify-content-center m-auto">
                    <Col className="col-lg-3 col-md-3  col-sm-5 ">
                      <button className="btn btn-warning w-100" type="submit">
                        {/* <a
                          href="https://teepmee.app.link/webapp2download"
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "white" }}
                        > */}
                        Save
                        {/* </a> */}
                      </button>
                    </Col>
                  </Row>
                  {/* <Row className="justify-content-center m-auto">
              <Col className="col-lg-6 col-md-10 col-12 col-sm-12">
                <Reward employeeProfile={employeeProfile} />
              </Col>
            </Row> */}
                </>
              ) : null}
            </div>
          )}
          {/* <BottomNav index={1} /> */}
        </Form>
      </Formik>
    </div>
  );
};

EditProfile.propTypes = {
  businessID: PropTypes.string,
};

EditProfile.defaultProps = {
  businessID: "",
};

const mapStateToProps = (state) => {
  return {
    businessID: state.appReducer.placeID,
  };
};

export default connect(mapStateToProps, null)(EditProfile);
