import React, {useEffect, useState} from "react";

import {Helmet} from "react-helmet";
import {Link, useHistory} from "react-router-dom";

import {Col, Jumbotron, Row} from "react-bootstrap";

import {Reward} from "../index";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import useApi from "../../hooks/useApi";
import * as api from "../../api/api";
import BottomNav from "../BottomNav/BottomNav";
import Sidebar from "../Sidebar/Sidebar";

const MainEmployeeProfile = (props) => {
  const {data, request} = useApi(api.getUserProfile);
  const [employeeProfile, setEmployeeProfile] = useState({});
  const [showSidebar, setShowSidebar] = useState(false);
  const [imageClass, setImageClass] = useState("landscape-img");
  const history = useHistory();

  const urlPath = window.location.href;
  const employeeID = urlPath.split("u/")[1];

  useEffect(() => {
    async function fetchData() {
      try {
        await request(employeeID);
      } catch (_) {}
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (!employeeID) {
      setEmployeeProfile(undefined);
    }

    fetch(`https://${process.env.REACT_APP_API}/u/${employeeID}`)
      .then((response) => response.json())
      .then((data) => setEmployeeProfile(data.data));
  }, [employeeID]);

  if (data && !data) {
    return <h1 className="text-center">Page not found</h1>;
  }

  const onImgLoad = ({target: img}) => {
    if (img.offsetHeight > img.offsetWidth) {
      setImageClass("portrait-img");
    }
  };

  const handleBackButton = () => {
    history.push(`/home`);
  };

  return (
    <div className="abc">
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <Helmet>
        <meta charSet="utf-8" />
        {employeeProfile && (
          <title>{`Teepmee - ${employeeProfile.name}`}</title>
        )}
      </Helmet>
      {employeeProfile && (
        <div>
          <Jumbotron className="mb-0">
            <Row className="justify-content-center">
              <Col className="col-lg-6 col-md-10 col-12 col-sm-12 d-flex justify-content-between">
                <div>
                  <i
                    class="fas fa-bars icons"
                    onClick={() => setShowSidebar(true)}
                  ></i>
                </div>
                {/* <Link to="/home" className="btn bg-white">
                  <i className="fa fa-arrow-left" />
                </Link> */}
                <Link to="#" className="btn bg-white">
                  <i className="fa fa-share-alt" />
                </Link>
              </Col>
            </Row>
          </Jumbotron>
          {employeeProfile ? (
            <>
              <Row className="mt-lg-n5 mt-md-n5 mt-sm-n5 mt-n5 mr-0">
                <Col className="col-lg-6 col-md-10 col-12 col-sm-12 mx-auto d-flex justify-content-center">
                  <div className="image-container">
                    <img
                      onLoad={onImgLoad}
                      className={imageClass}
                      alt=""
                      src={employeeProfile.photo_url}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center m-auto">
                <Col className="col-lg-6 col-md-10 col-12 col-sm-12 text-center">
                  <h2 className="profile-color">{employeeProfile.name}</h2>
                  <p className="profile-color">
                    Username: {employeeProfile.user_name}
                  </p>
                  {employeeProfile.job_title ? (
                    <p className="profile-color">{employeeProfile.job_title}</p>
                  ) : null}
                  {employeeProfile.description ? (
                    <p className="profile-color">
                      About me: {employeeProfile.description}
                    </p>
                  ) : null}

                  {/* <p className="text-muted">{employeeProfile.description}</p> */}
                </Col>
              </Row>
              {/* <Row className="justify-content-center m-auto">
                <Col className="col-lg-3 col-md-3  col-sm-5 ">
                  <p className="profile-color">Bio</p>

                  <textarea
                    className="bio "
                    placeholder="I hope you are enjoying working with me!"
                    disabled
                  ></textarea>
                </Col>
              </Row>
              <Row className="justify-content-center m-auto">
                <Col className="col-lg-3 col-md-3  col-sm-5 ">
                  <button className="btn btn-warning w-100">
                    <a
                      href="https://teepmee.app.link/webapp2download"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "white" }}
                    >
                      Modify with the App
                    </a>
                  </button>
                </Col>
              </Row> */}
              <Row className="justify-content-center m-auto">
                <Col className="col-lg-6 col-md-10 col-12 col-sm-12">
                  {employeeProfile && (
                    <Reward employeeProfile={employeeProfile} />
                  )}
                </Col>
              </Row>
            </>
          ) : null}
        </div>
      )}
      {/* <BottomNav index={1} /> */}
    </div>
  );
};

MainEmployeeProfile.propTypes = {
  businessID: PropTypes.string,
};

MainEmployeeProfile.defaultProps = {
  businessID: "",
};

const mapStateToProps = (state) => {
  return {
    businessID: state.appReducer.placeID,
  };
};

export default connect(mapStateToProps, null)(MainEmployeeProfile);
