import * as TYPES from '../actionTypes';

const initialState = {
    searchResult: [],
    location: '',
    businessName: '',
    placeID: '',
}

export const appReducer = (state=initialState, action) => {
    switch (action.type) {
        case TYPES.SET_SEARCH_RESULTS:
            return {
                ...state,
                searchResult: [ ...action.payload.results ],
                location: action.payload.location,
                businessName: action.payload.businessName,
            }
        case TYPES.SELECT_BUSINESS:
            return {
                ...state,
                placeID: action.payload,
            }
        default:
            return state
    }
}
