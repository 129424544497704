import React, { useEffect } from "react";
import useApi from "../../hooks/useApi";
import WorkplaceScreen from "./WorkplaceScreen";
import * as api from "../../api/api";

const Workplace = () => {
  const { request, data } = useApi(api.getWorkplaces);
  useEffect(() => {
    async function fetchData() {
      try {
        await request();
      } catch (_) {}
    }
    fetchData();
  }, []);
  return <WorkplaceScreen data={data} />;
};

export default Workplace;
