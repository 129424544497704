import React, { Component } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Home from "./components/Home";
import BusinessProfile from "./components/BusinessProfile";
import Profile from "./components/EmployeeProfile";

import "./App.css";
import SignUp from "./components/Login/Signup/SignUp";
import Verification from "./components/Login/Verification/Verification";
import ProfileName from "./components/PorfileName/ProfileName";
import UploadAvatar from "./components/UploadAvatar/UploadAvatar";
import CongratulationsScreen from "./components/Congratulations/CongratulationsScreen";
import PaymentMethod from "./components/PaymentMethods/PaymentMethod";
import Workplace from "./components/Workplace/Workplace";
import WorkplaceHomeScreen from "./components/Workplace/WorkplaceHomeScreen";
import MainBusinessProfile from "./components/PrevFlow/MainBusinessProfile";
import MainProfile from "./components/PrevFlow/MainProfile";
import Share from "./components/Share/Share";
import CreateUser from "./components/Admin/CreateUser";
import EditProfile from "./components/Admin/EditProfile";
import EditPayment from "./components/Admin/EditPayment/EditPayment";

class App extends Component {
  handleMount() {
    sessionStorage.setItem("first-mount", "true");
    return <Redirect to="/home" />;
  }

  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route exact path="/">
              {sessionStorage.getItem("first-mount") === "true" ? (
                <SignUp />
              ) : (
                this.handleMount()
              )}
            </Route>
            <Route path="/verify" component={Verification} />
            <Route path="/profileName" component={ProfileName} />
            <Route path="/congratulations" component={CongratulationsScreen} />
            <Route path="/paymentMethods" component={PaymentMethod} />
            <Route path="/avatar" component={UploadAvatar} />
            <Route path="/home" component={Home} />
            <Route path="/workplace" component={WorkplaceHomeScreen} />
            <Route path="/main_b/" component={BusinessProfile} />
            <Route path="/b/" component={MainBusinessProfile} />
            <Route path="/main_profile" component={Profile} />
            <Route path="/u" component={MainProfile} />
            <Route path="/workplaceHome" component={Workplace} />
            <Route path="/share" component={Share} />
            <Route path="/login" component={SignUp} />
            <Route path="/create-user" component={CreateUser} />
            <Route path="/edit-profile" component={EditProfile} />
            <Route path="/edit-payment" component={EditPayment} />
          </Switch>
        </Router>
      </>
    );
  }
}

export default App;
