import React, { useState } from "react";
import { Jumbotron, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import BottomNav from "../BottomNav/BottomNav";
import "./Workplace.css";
import Sidebar from "../Sidebar/Sidebar";

const WorkplaceScreen = ({ data }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <>
      <div>
        <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
        <Jumbotron className="mb-0 pb-5">
          <Row className="justify-content-center">
            <Col className="col-lg-6 col-md-10 col-12 col-sm-12 d-flex justify-content-between">
              <div>
                <i
                  class="fas fa-bars icons"
                  onClick={() => setShowSidebar(true)}
                ></i>
              </div>
            </Col>
          </Row>
        </Jumbotron>

        <section class="login_section">
          <div class="login_container workplace-list">
            <div class="added_payment_list">
              {data &&
                data.data.map((workplace) => (
                  <div class="single_workplace_list">
                    <div class="single_workplace_list_data">
                      <i class="far fa-building"></i>
                      <div class="workplace_data">
                        <h4>{workplace.workplace.name}</h4>
                        <p>{workplace.workplace.address}</p>
                        <span>
                          Works here since {workplace.start_month}/
                          {workplace.start_year}
                        </span>
                      </div>
                    </div>
                    <a href="https://teepmee.app.link/webapp2download">
                      <i class="fas fa-trash-alt"></i>
                    </a>
                  </div>
                ))}
            </div>

            <div class="add_workplace_btn">
              <Link to="/workplace">Add work place</Link>
            </div>
          </div>
        </section>
      </div>
      <BottomNav index={2} />
    </>
  );
};

export default WorkplaceScreen;
