import React, {useEffect, useState} from "react";

import {Helmet} from "react-helmet";
import {connect} from "react-redux";

import {
  Alert,
  Container,
  Jumbotron,
  Button,
  InputGroup,
  FormControl,
  Col,
  Row,
  Form,
} from "react-bootstrap";

import location_icon from "../assets/images/location.png";
import building_icon from "../assets/images/building.png";
import {MobileBanner, Listing} from "../components";

import {setSearchResults} from "../redux/actions";
import PropTypes from "prop-types";

import loadScript from "../components/helper-components/utils";
import Sidebar from "./Sidebar/Sidebar";
import BottomNav from "./BottomNav/BottomNav";
import MainListing from "./PrevFlow/MainListing";

const Home = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [query, setQuery] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);

  const [showError, setShowError] = useState(false);
  const [location, setLocation] = useState(props.location);
  const [businessName, setBusinessName] = useState(props.businessName);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    if (!window.google) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places`
      );
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (query) {
      const request = {query};
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.textSearch(request, function (apiResults, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          props.setSearchResults(apiResults, location, businessName);
        }
      });
    }
  }, [query]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (businessName === "" && location === "") {
      setShowError(true);
    } else {
      setShowError(false);
      setQuery(businessName + " " + location);
    }
  };

  const {results} = props;
  let isMobile = width <= 768;

  return (
    <div className="abc">
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Teepmee - Tipping made easy</title>
      </Helmet>
      <div>
        <Form onSubmit={handleSubmit}>
          <Jumbotron className="mb-0">
            <Row className="justify-content-center">
              <Col className="col-lg-8 col-md-10">
                <div>
                  <i
                    class="fas fa-bars icons"
                    onClick={() => setShowSidebar(true)}
                  ></i>
                </div>
                <h1 style={{color: "white"}}>Welcome to Teep·Mee!</h1>
                <p>
                  Did someone provide you with exceptional service? Find them
                  and show your appreciation!
                </p>
                <Row>
                  {showError && (
                    <Col className="col-12">
                      <Alert variant="danger" className="text-danger">
                        <i className="fa fa-exclamation-triangle pr-2" /> Please
                        enter at least location or business name!
                      </Alert>
                    </Col>
                  )}
                  <Col className="col-md-6 col-12">
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="location-icon">
                          <img alt="" src={location_icon} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder="Enter City"
                        aria-label="location"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col className="col-md-6 col-12">
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="building-icon">
                          <img alt="" src={building_icon} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder="Enter business name"
                        aria-label="business name"
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <p>
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    className="float-right"
                    variant="warning"
                  >
                    Search
                  </Button>
                </p>
              </Col>
            </Row>
          </Jumbotron>
          <MobileBanner />
          <MainListing type={0} />
        </Form>
      </div>
      {localStorage.getItem("token") && <BottomNav index={0} />}
    </div>
  );
};

Home.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSearchResults: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    results: state.appReducer.searchResult,
    location: state.appReducer.location,
    businessName: state.appReducer.businessName,
  };
};

export default connect(mapStateToProps, {setSearchResults})(Home);
