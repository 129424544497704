import React, {useState, useEffect} from "react";

import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {Row, Container, Jumbotron, Col} from "react-bootstrap";

import {EmployeeFilter, EmployeesList} from "../index";
import loadScript from "../helper-components/utils";
import Sidebar from "../Sidebar/Sidebar";
import {getUserBusinesses} from "../../api/api";
// import AddJob from "./AddJob/AddJob";

const MainBusinessProfile = (props) => {
  const [place, setPlace] = useState({});
  const [rerender, setRerender] = useState({});
  const [placePicture, setPlacePicture] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);
  const [managerBusiness, setManagerBusiness] = useState([]);
  const [loading, setLoading] = useState(true);

  const urlPath = window.location.pathname;
  const placeId = urlPath.split("b/")[1] || props.placeID;

  useEffect(() => {
    if (!window.google) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places`
      );
      setTimeout(() => setRerender({}), 200);
    }
    if (!placeId) {
      setPlace(undefined);
      return;
    }
    if (window.google) {
      const request = {placeId};
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.getDetails(request, function (placeDetails, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setPlace(placeDetails);
          placeDetails.photos &&
            setPlacePicture(placeDetails.photos[0].getUrl({maxWidth: 512}));
        }
      });
    }
  }, [rerender]);

  useEffect(() => {
    if(localStorage.getItem('token')){
      getAdminWorkplace();
    } else {
      setLoading(false)
    }
  }, []);

  const getAdminWorkplace = async () => {
    try {
      const {data} = await getUserBusinesses();
      if (data?.data?.length > 0) {
        const businesses = data?.data?.map((item) => item.place_id);
        setManagerBusiness(businesses);
      }
    } catch (error) {
      console.log({error});
    }
    setLoading(false);
  };

  if (!place) {
    return <h1 className="text-center">Page not found</h1>;
  }

  return (
    // Object.keys(place).length > 0 && (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Teepmee - ${place.name}`}</title>
      </Helmet>
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />

      <Jumbotron
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${placePicture})`,
          position: "fixed",
          width: "100%",
          zIndex: 9999,
        }}
        className="hero-image"
      >
        <Row className="justify-content-center">
          <Col className="col-lg-6 col-md-10 col-12 col-sm-12">
            <div>
              <i
                class="fas fa-bars icons"
                onClick={() => setShowSidebar(true)}
              ></i>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5 mb-0">
          <Col className="col-lg-6 col-md-10 col-12 col-sm-12">
            <h4 style={{color: "white"}}>{place.name}</h4>
            <p className="small">{place.formatted_address}</p>
          </Col>
        </Row>
      </Jumbotron>
      <div>
        <Row className="justify-content-center m-auto">
          <Col className="col-lg-6 col-md-10 col-12 col-sm-12 employeeList">
            {/* <EmployeeFilter /> */}
            {(!loading && place.place_id) ? (
              <EmployeesList
                placeId={place.place_id}
                isManager={managerBusiness.includes(place?.place_id)}
              />
            ) : null}
            {/* <AddJob place={place} /> */}
          </Col>
        </Row>
      </div>
    </>
    // )
  );
};

MainBusinessProfile.propTypes = {
  placeID: PropTypes.string,
};

MainBusinessProfile.defaultProps = {
  placeID: null,
};

const mapStateToProps = (state) => {
  return {
    placeID: state.appReducer.placeID,
  };
};

export default connect(mapStateToProps, null)(MainBusinessProfile);
