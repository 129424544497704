import React from "react";
import { Link } from "react-router-dom";
import "./BottomNav.css";

const BottomNav = ({ index }) => {
  return (
    <div className="test">
      <div className="login_container">
        <div className="bottom-nav">
          <ul>
            <Link to="/home">
              <li className={index === 0 ? "active-nav" : ""}>
                <i className="fas fa-envelope-open"></i>
                <span>Home</span>
              </li>
            </Link>
            <Link to="/main_profile">
              <li className={index === 1 ? "active-nav" : ""}>
                <i className="fas fa-user"></i>
                <span>Profile</span>
              </li>
            </Link>
            <Link to="/workplaceHome">
              <li className={index === 2 ? "active-nav" : ""}>
                <i className="fas fa-briefcase"></i>
                <span>Work</span>
              </li>
            </Link>
            <Link to="/paymentMethods">
              <li className={index === 3 ? "active-nav" : ""}>
                <i className="fas fa-wallet"></i>
                <span>Payment</span>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BottomNav;
